import * as THREE from "three";
import { TextGeometry } from "three/examples/jsm/geometries/TextGeometry";
import { G } from "../../globals";
import { CONFIG } from "../../config";
import Entity from "../Entity";
import Loop from "../Loop";
import U from "../../utils";

const markersUsingThisType = [];

class AmenityMarker extends Entity {
	constructor(params) {
		super();

		markersUsingThisType.push(params.name);

		this.marker = new THREE.Group();
		this.markerVLookGroup = new THREE.Group();
		this.markerLookGroup = new THREE.Group();
		this.markerLabelGroup = new THREE.Group();
		this.markerStaticGroup = new THREE.Group();

		this.params = params;

		this.markerIcon = new THREE.Mesh(
			new THREE.PlaneGeometry(params.iconSize.width, params.iconSize.height),
			new THREE.MeshBasicMaterial({
				color: new THREE.Color("#ffffff"),
				map: params.icon,
				transparent: true,
				toneMapped: false,
				// depthTest: false
			})
		);

		this.markerIcon.renderOrder = 2;

		const headlineText = new TextGeometry(params.markerData.text, {
			font: params.font,
			size: 1.5,
			height: 0.01,
			curveSegments: CONFIG.r_textCurveSegments,
		});

		const headlineSubText = new TextGeometry(params.markerData.subText, {
			font: params.font,
			size: 0.7,
			height: 0.01,
			curveSegments: CONFIG.r_textCurveSegments,
		});

		this.headlineMesh = new THREE.Mesh(
			headlineText,
			new THREE.MeshBasicMaterial({
				color: new THREE.Color("#0A2342"),
				transparent: true,
				depthTest: false,
				toneMapped: false,
			})
		);

		this.headlineMesh.renderOrder = 4;

		this.headlineSubMesh = new THREE.Mesh(
			headlineSubText,
			new THREE.MeshBasicMaterial({
				color: new THREE.Color("#0A2342"),
				transparent: true,
				depthTest: false,
				toneMapped: false,
			})
		);

		this.headlineSubMesh.renderOrder = 4;

		this.markerLabelGroup.add(this.markerIcon);
		this.markerLabelGroup.add(this.headlineMesh);
		this.markerLabelGroup.add(this.headlineSubMesh);

		this.markerVLookGroup.add(this.markerStaticGroup);
		this.markerVLookGroup.add(this.markerLabelGroup);
		this.markerLookGroup.add(this.markerVLookGroup);

		this.marker.add(this.markerLookGroup);

		this.headlineMesh.visible = false;
		this.headlineSubMesh.visible = false;

		this.isActive = false;
		this.params = params;

		this.markerIcon.material.side = THREE.DoubleSide;
		this.markerIcon.material.map.anisotropy = CONFIG.r_anisotropy;
		this.markerIcon.material.map.encoding = THREE.sRGBEncoding;

		this.marker.position.set(0, 5, 0);
		this.headlineMesh.position.set(0, 5, 0);
		this.headlineSubMesh.position.set(0, 3, 0);

		this.enableInteraction(this.markerIcon);

		this.loop = new Loop(() => {
			if (this.marker.visible) this.RotateToFaceCamera();
		}).start();

		setTimeout(() => {
			this.headlineMesh.geometry.computeBoundingBox();
			this.headlineSubMesh.geometry.computeBoundingBox();
			const centerHeadlinePos = {
				x:
					(this.headlineMesh.geometry.boundingBox.max.x -
						this.headlineMesh.geometry.boundingBox.min.x) /
					2,
				z:
					(this.headlineMesh.geometry.boundingBox.max.z -
						this.headlineMesh.geometry.boundingBox.min.z) /
					2,
			};
			const centerHeadlineSubPos = {
				x:
					(this.headlineSubMesh.geometry.boundingBox.max.x -
						this.headlineSubMesh.geometry.boundingBox.min.x) /
					2,
				z:
					(this.headlineSubMesh.geometry.boundingBox.max.z -
						this.headlineSubMesh.geometry.boundingBox.min.z) /
					2,
			};
			this.headlineMesh.position.set(
				-centerHeadlinePos.x,
				this.headlineMesh.position.y,
				-centerHeadlinePos.z
			);
			this.headlineSubMesh.position.set(
				-centerHeadlineSubPos.x,
				this.headlineSubMesh.position.y,
				-centerHeadlineSubPos.z
			);

			this.marker.scale.set(
				this.marker.scale.x * params.forceScale,
				this.marker.scale.y * params.forceScale,
				this.marker.scale.z * params.forceScale
			);

			const correctedPos = new THREE.Vector3(
				params.markerData.location.x,
				params.markerData.height ? params.markerData.height : 5,
				params.markerData.location.z
			);
			// correctedPos.applyAxisAngle(new THREE.Vector3(0, 1, 0), 3);

			this.marker.position.set(
				correctedPos.x,
				params.markerData.height ? params.markerData.height : 5,
				correctedPos.z
			);
		}, 300);
	}

	EnableMarker() {
		AmenityMarker.ResetAllMarkers();
		this.markerIcon.material.map = this.params.iconActive;
		this.headlineMesh.visible = true;
		this.headlineSubMesh.visible = true;
		this.isActive = true;
	}

	DisableMarker() {
		this.markerIcon.material.map = this.params.icon;
		this.headlineMesh.visible = false;
		this.headlineSubMesh.visible = false;
		this.isActive = false;
	}

	onMouseEnter() {
		if (this.isVisible) {
			this.markerIcon.material.color = new THREE.Color(
				this.isActive ? "#ffffff" : "#cccccc"
			);
			document.body.style.cursor = "pointer";
		}
	}

	onMouseLeave() {
		if (this.isVisible) {
			this.markerIcon.material.color = new THREE.Color("#FFFFFF");
			document.body.style.cursor = "auto";
		}
	}

	onClick() {
		if (this.isActive) {
			//MARKER IS OFF
			this.DisableMarker();
		} else if (!this.isActive) {
			//MARKER IS ON
			this.EnableMarker();
		}
	}

	RotateToFaceCamera() {
		if (G.cam.camera.getPolarAngle && G.cam.camera.getPolarAngle() < 0.25) {
			this.markerLookGroup.rotation.set(-Math.PI / 2, 0, G.cam.camera.getAzimuthalAngle());
			this.markerVLookGroup.rotation.set(0, 0, 0);
		} else if (G.cam.camera.getPolarAngle) {
			this.markerLookGroup.rotation.set(0, G.cam.camera.getAzimuthalAngle(), 0);
			this.markerVLookGroup.rotation.set(
				-U.MapRange(G.cam.camera.getPolarAngle(), 0, Math.PI / 2, Math.PI / 2, 0),
				0,
				0
			); //polar angle = 1.5 (half pi) -  angle should be 0, polar angle = 0 - angle should be 1.5 (or half pi)
			// this.markerLookGroup.lookAt(G.cam.camera.position);
		}
	}

	static ResetAllMarkers() {
		markersUsingThisType.map((type) => {
			G.markers[type].m.map((m) => m.DisableMarker());
			return null;
		});
	}
}

export default AmenityMarker;
