import React, { Component } from "react";

class Instruction extends Component {
	render() {
		return (
			<li
				key={this.props.key}
				className={`instruction instruction-hide ${this.props.icon} ${
					this.props.show ? "" : "hidden"
				}`}
			>
				{this.props.children}
			</li>
		);
	}
}

export default Instruction;