import React, { Component } from "react";
import * as THREE from "three";
import U from "../utils";

class Loader extends Component {
	constructor() {
		super();

		this.SetProgress = this.SetProgress.bind(this);
		// this.OutroAnim = this.OutroAnim.bind(this);

		THREE.DefaultLoadingManager.onProgress = (url, itemsLoaded, itemsTotal) => {
			this.SetProgress((itemsLoaded / itemsTotal) * 100);
		};

		THREE.DefaultLoadingManager.onLoad = () => {
			setTimeout(() => {
				this.OutroAnim();
				this.FireEvent();
			}, 1000);
		};
	}

	componentDidMount() {
		this.IntroAnim();
	}

	SetProgress(percentage) {
		this.progress.style.clipPath = `inset(0% ${100 - U.Clamp(percentage, 0, 100)}% 0% 0%)`;
		// this.progressDiv.style.width = `${U.Clamp(percentage, 0, 100)}%`;
		// this.loadingPercent.innerHTML = `${U.RoundNum(percentage, 0)}%`;
	}

	FireEvent() {
		console.log("LOAD DONE!");
		const eventDelegate = new CustomEvent("loadDone", {
			bubbles: true,
			detail: "LoadComplete",
		});
		document.dispatchEvent(eventDelegate);
	}

	IntroAnim() {
		setTimeout(() => {
			this.group.classList.remove("fade");
		}, 200);
	}

	OutroAnim() {
		this.content.classList.add("fade");
		setTimeout(() => {
			this.bg.classList.add("loader-outro");
			setTimeout(() => {
				this.bg.classList.add("hidden");
			}, 1000);
		}, 600);
	}

	render() {
		return (
			<div className="loader">
				<div ref={(ref) => (this.bg = ref)} className="loader-bg"></div>
				<div ref={(ref) => (this.content = ref)} className="loader-content">
					<div ref={(ref) => (this.group = ref)} className="loader-group fade">
						<div className="loader-mask"></div>
						<div ref={(ref) => (this.progress = ref)} className="loader-progress"></div>
						<div className="loader-plate"></div>
					</div>
					<div className="loader-gif"></div>
				</div>
			</div>
		);
	}
}

export default Loader;
