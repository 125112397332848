import React, { Component } from "react";
import { G } from "../globals";
import Player from "@vimeo/player";

class OverlayVideo extends Component {

	componentDidMount() {
		this.player = new Player("video", {
			url: this.props.content
		});
		this.player.on("loaded", () => {
			const iframe = document.querySelector(".video iframe");
			iframe.classList.add("video-full");
			this.player.play();
		});

		this.player.on("ended", () => {
			G.showOverlay('showVideo', false);
		});

		
	}
	

	render() {
		return (
			<div className="overlay gallery-overlay clickable">
				<div className="gallery-viewer video-viewer">
					<div id="video" className="video"></div>
				</div>
				<div className="slide-close-container">
					<div
						className="slide-close"
						onClick={() => G.showOverlay("showVideo", false)}
					></div>
				</div>
				<div className="overlay-bg blue-bg"></div>
			</div>
		);
	}
}

export default OverlayVideo;
