import * as THREE from "three";
import Entity from "./Entity";
import Loop from "./Loop";
import { G } from "../globals";
import U from "../utils";

class Interact extends Entity {
  constructor() {
    super();

    //keep track of intersects
    this.intersected = [];

    this.UpdateMousePos = this.UpdateMousePos.bind(this);
    this.DetectClick = this.DetectClick.bind(this);
    this.UnClick = this.UnClick.bind(this);

    this.mouse = new THREE.Vector3();
    this.raycaster = new THREE.Raycaster();

    this.clicked = false;
    //this is a flag to ignore other inputs on mouseDown events
    this.focussed = false;
    G.interactionInUse = false;

    //preventing interaction if UI is obscuring the model
    this.isMouseBlockedByInterface = false;

    if (U.GetResponsiveMode().isTouch) {
      window.addEventListener("touchstart", (e) => {
        this.UpdateMousePos(e.touches[0]);
        this.DetectClick(e);
      });
      window.addEventListener("touchmove", (e) => {
        this.UpdateMousePos(e.touches[0]);
      });
      window.addEventListener("touchend", this.UnClick);
    } else {
      document.addEventListener("mousemove", this.UpdateMousePos);
      document.addEventListener("mousedown", this.DetectClick);
      document.addEventListener("mouseup", this.UnClick);
    }

    // const testObj = this.CreateDebugPoint();

    this.interactionLoop = new Loop(() => {
      G.debug.TrackVar("mousePos", `x: ${this.mouse.x} // y: ${this.mouse.y}`);
      // G.debug.TrackVar("raycastLayer", G.raycastLayer.length);

      //check if camera is currently moving
      if (G.cam.controls.inUse) {
        return;
      }

      if (G.forceDisableInteraction) {
        return;
      }

      // console.log(G.raycastLayer);

      this.raycaster.setFromCamera(this.mouse, G.cam.camera);
      let intersects = this.raycaster.intersectObjects(G.raycastLayer, true);
      G.debug.TrackVar("intersects", intersects.length);

      //found something!
      if (intersects.length > 0) {
        // console.log(intersects[0].object);
        // console.log("CLICK!!!");

        //PERFORMANCE ISSUE IN THE MAKING???
        intersects.sort(function (a, b) {
          return b.object.raycastLayerOrder - a.object.raycastLayerOrder;
        });

        // console.log(intersects);

        G.debug.TrackVar(
          "interactPoint",
          `x: ${intersects[0].point.x} // z: ${intersects[0].point.z}`
        );
        // testObj.position.set(intersects[0].point.x, intersects[0].point.y, intersects[0].point.z);

        if (this.isMouseBlockedByInterface) return;

        for (let i = 0; i < intersects.length; i++) {
          //only detect hover on first object
          // console.log(intersects[0].object.name);
          if (i === 0 && intersects[i].object && intersects[i].object.entity) {
            intersects[i].object.entity.onMouseEnterHandler({
              uuid: intersects[i].object.uuid,
              obj: intersects[i].object,
              objPos: intersects[i].point,
              screenPos: { x: this.mouse.x, y: this.mouse.y },
            });
            intersects[i].object.entity.onMouseOverHandler({
              uuid: intersects[i].object.uuid,
              obj: intersects[i].object,
              objPos: intersects[i].point,
              screenPos: { x: this.mouse.x, y: this.mouse.y },
            });

            if (
              !this.focussed &&
              this.clicked &&
              intersects[i].object.entity &&
              !intersects[i].object.entity.isClicked
            ) {
              intersects[i].object.entity.onClickHandler({
                uuid: intersects[i].object.uuid,
                obj: intersects[i].object,
                objPos: intersects[i].point,
                screenPos: { x: this.mouse.x, y: this.mouse.y },
              });
              intersects[i].object.entity.onMouseDownHandler({
                uuid: intersects[i].object.uuid,
                obj: intersects[i].object,
                objPos: intersects[i].point,
                screenPos: { x: this.mouse.x, y: this.mouse.y },
              });

              this.focussed = true;
            } else if (
              !this.clicked &&
              intersects[0].object.entity &&
              intersects[0].object.entity.isClicked
            ) {
              intersects[i].object.entity.onMouseUpHandler({
                uuid: intersects[i].object.uuid,
                obj: intersects[i].object,
                objPos: intersects[i].point,
                screenPos: { x: this.mouse.x, y: this.mouse.y },
              });
              intersects[0].object.entity.isClicked = false;
            }

            for (let i = 0; i < G.raycastLayer.length; i++) {
              //Edge case where going from 2 to 1 intersect was leaving the second one without a leave event
              if (intersects[0].object.uuid !== G.raycastLayer[i].entity.uuid)
                G.raycastLayer[i].entity.onMouseLeaveHandler();
            }
            // G.interactionInUse = false;
          }
        }
      } else {
        for (let i = 0; i < G.raycastLayer.length; i++) {
          G.raycastLayer[i].entity.onMouseLeaveHandler();
        }
        G.interactionInUse = false;
      }
    });
  }

  onStateChange(newState) {
    this.interactionLoop.stop();
    if (newState.camData && newState.camData.interactable) {
      this.interactionLoop.start();
    }
  }

  UpdateMousePos(e) {
    if (
      e.target.nodeName === "CANVAS" ||
      (e.target.classList && e.target.classList.contains("pass-through"))
    )
      this.isMouseBlockedByInterface = false;
    else this.isMouseBlockedByInterface = true;

    this.mouse.x = (e.clientX / window.innerWidth) * 2 - 1;
    this.mouse.y = (-e.clientY / window.innerHeight) * 2 + 1;

    // console.log(e);
  }

  DetectClick(e) {
    this.clicked = true;
    // this.FireEvent("intClick");
  }

  UnClick() {
    this.clicked = false;
    this.focussed = false;
    G.interactionInUse = false;
    // for (let i = 0; i < G.raycastLayer.length; i++) {
    // 	G.raycastLayer[i].entity.onMouseUpHandler();
    // }
  }
}

export default Interact;
