import U from "../utils";

export const basicTransportMarkers = [
	{
		text: "Paddington Station",
		height: 5,
		location: {x: 55.265, y: 0, z: 199.189},
	},
];

export const poiMarkers = [
	{
		text: "Talbot Square Gardens",
		location: {x: -81.828, y: 0, z: -6.076},
		height: 4,
	},
	{
		text: "Hyde Park",
		location: {x: -20.292, y: 0, z: -415.717},
		height: 4,
	},
	{
		text: "Merchant Square",
		location: {x: -358.201, y: 0, z: 423.453},
		height: 4,
	},
	{
		text: "Paddington Central",
		location: {x: 328.436, y: 0, z: 556.694},
		height: 7,
	},
];

export const connectionsMarkers = [
	{
		text: "Paddington",
		height: 7,
		location: {x: -34.687, y: 0, z: 54.432},
	},

	{
		text: "Lancaster Gate",
		height: 10,
		location: {x: 14.893, y: 0, z: -274.775},
	},
	{
		text: "Edgeware Road",
		height: 5,
		location: {x: -477.97, y: 0, z: 528.136},
	},
];

export const elizabethStationMarker = [
	{
		text: "",
		height: 10,
		location: {x: 58.657, y: 0, z: 83.893},
	},
];

export const busMarkers = [
	{
		text: "",
		height: 5,
		location: {x: 87.381, y: 0, z: -14.512},
	},
	{
		text: "",
		height: 5,
		location: {x: -9.054, y: 0, z: 49.215},
	},
	{
		text: "",
		height: 5,
		location: {x: -124.713, y: 0, z: 139.594},
	},
	{
		text: "",
		height: 5,
		location: {x: 201.788, y: 0, z: 261.819},
	},
];

export const bikeMarkers = [
	{
		text: "",
		height: 5,
		location: {x: 495.334, y: 0, z: 147.858},
	},
	{
		text: "",
		height: 5,
		location: {x: 290.271, y: 0, z: -122.763},
	},
	{
		text: "",
		height: 5,
		location: {x: 221.908, y: 0, z: 217.3},
	},
];

