import React, { Component } from "react";
import qualityImg from "../images/featureQuality_hero.jpg";
import qualityIllust from "../images/featureQuality_illust.jpg";

class FeatureQuality extends Component {
	constructor() {
		super();

		this.introAnim = this.introAnim.bind(this);
		this.outroAnim = this.outroAnim.bind(this);
	}

	componentDidMount() {
		document.addEventListener("camDone", this.introAnim);
		document.addEventListener("stateWillChange", this.outroAnim);
	}

	componentWillUnmount() {
		document.removeEventListener("camDone", this.introAnim);
		document.removeEventListener("stateWillChange", this.outroAnim);
	}

	introAnim(e) {
		if (!e.detail.state || e.detail.state.name !== "FEATURE_QUALITY") return;

		setTimeout(() => {
			const slideDiv = document.querySelector(".slide");
			this.fadeDivs = [...slideDiv.querySelectorAll(".fade-init")];
			this.heroImg.classList.add("swipe-reveal");
			setTimeout(() => {
				this.featureDiv.classList.add("swipe-reveal");
				this.fadeDivs.map((f) => f.classList.remove("fade"));
			}, 400);
		}, 100);
	}

	outroAnim(e) {
		if (e.detail.newState && e.detail.newState.name === "FEATURE_QUALITY") return;
		const swipeOuts = [...document.querySelectorAll(".swipe-init")];
		const fadeOuts = [...document.querySelectorAll(".fade-init")];
		swipeOuts.map((s) => {
			s.classList.add("swipe-out");
		});
		fadeOuts.map((f) => {
			f.classList.add("fade");
		});
	}

	render() {
		return (
			<div className="slide feature-slide clickable">
				<div className="slide-close-container">
					<div
						className="slide-close"
						onClick={() => {
							this.props.GoToState("OVERVIEW");
						}}
					></div>
				</div>
				<div className="feature-slide-wrapper">
					<div
						className="feature-slide-container swipe-init"
						ref={(ref) => (this.featureDiv = ref)}
					>
						<div className="enlarge-button feature-button"></div>
						<div
							className="feature-img-block"
							style={{ backgroundImage: `url(${qualityIllust})` }}
						></div>
						<p className="feature-title">
							Exceptional quality and bespoke finishes, truly best in class.{" "}
						</p>
						<p className="small-copy">
							Fifty Paddington is a high-quality development designed to provide
							occupiers with a flexible blank canvas to re-imagine the way they work
							post-pandemic. With bespoke finishes and exquisite attention to detail,
							the main entrance offers a striking arrival experience with an in-built
							café ideal as an extended, informal meeting space.
						</p>

						<p className="small-copy">
							Our communal meeting and landscaped terrace on the 6th floor provide
							opportunities for versatile working and a further space to collaborate.
							This bookable event space features bifold doors providing fresh air and
							an ‘outside-in’ environment leading out onto a large furnished terrace
							with impressive views.
						</p>

						<p className="small-copy">
							Speaking of impressive, the facade is adorned with an art piece by
							Maarten Baas – a large clock – a symbol of patronage and a landmark for
							the area. The piece, part functional and part public art performance,
							references Paddington Station's architect Isambard Kingdom Brunel as he
							paints the current time on the clock's dial.
						</p>

						<div className="feature-vert-title">
							<div className="title-vert-rotate">
								<h2 className="h2 feature-vert">Exceptional Quality</h2>
							</div>
						</div>
					</div>
				</div>
				<div className="feature-slide-main">
					<div
						ref={(ref) => (this.heroImg = ref)}
						style={{
							backgroundImage: `url(./images/gallery/fit3.jpg)`,
							backgroundSize: "cover",
						}}
						className="feature-slide-image swipe-init"
					></div>
				</div>
			</div>
		);
	}
}

export default FeatureQuality;
