import React, { Component } from "react";
import { G } from "../globals";

class Location extends Component {
  constructor() {
    super();

    this.introAnim = this.introAnim.bind(this);
    this.outroAnim = this.outroAnim.bind(this);

    this.showFood = false;
    this.showHealth = false;
    this.showHotels = false;
  }

  componentDidMount() {
    document.addEventListener("camDone", this.introAnim);
    document.addEventListener("stateWillChange", this.outroAnim);
  }

  componentWillUnmount() {
    document.removeEventListener("camDone", this.introAnim);
    document.removeEventListener("stateWillChange", this.outroAnim);
  }

  introAnim(e) {
    if (!e.detail.state || e.detail.state.name !== "LOCATION") return;

    G.ToggleMarkers("foodAmenityMarkers", false);
    G.ToggleMarkers("wellAmenityMarkers", false);
    G.ToggleMarkers("hotelAmenityMarkers", false);

    setTimeout(() => {
      const slideDiv = document.querySelector(".slide");
      this.fadeDivs = [...slideDiv.querySelectorAll(".fade-init")];
      this.slidePopout.classList.add("swipe-reveal");
      setTimeout(() => {
        this.slideBg.classList.add("swipe-reveal");
        this.fadeDivs.map((f) => f.classList.remove("fade"));
      }, 200);
    }, 100);
  }

  outroAnim(e) {
    if (e.detail.newState && e.detail.newState.name === "LOCATION") return;
    const swipeOuts = [...document.querySelectorAll(".swipe-init")];
    const fadeOuts = [...document.querySelectorAll(".fade-init")];
    swipeOuts.map((s) => {
      s.classList.add("swipe-out");
    });
    fadeOuts.map((f) => {
      f.classList.add("fade");
    });
  }

  render() {
    return (
      <div className="slide">
        <div className="slide-placer"></div>
        <div className="slide-panel panel-red parallax clickable">
          <div parallax-rate={6} className="slide-popout location-popout">
            <div
              ref={(ref) => (this.slidePopout = ref)}
              className="video-player swipe-init"
              onClick={() => {
                G.showOverlay(
                  "showVideo",
                  "https://vimeo.com/646928573/54e3ea698f"
                );
              }}
            >
              <div className="video-placeholder"></div>
              <div className="video-play-button"></div>
            </div>
          </div>
          <div className="panel-padding-container fade-init fade">
            <div className="location-title-block">
              <div className="copy">
                Fifty Paddington is your opportunity to be located amongst the
                rich mix of leisure, retail and global office occupiers.
              </div>
              <div className="amenity-toggle">
                <p className="amenity-label">Amenities</p>
                <ul className="slide-horiz-nav amenity-toggle-nav w-list-unstyled">
                  <li
                    className="slide-horiz-item amenity-toggle-item"
                    onClick={() => {
                      G.showOverlay("showOccupiers", true);
                    }}
                  >
                    <strong>show neighbours</strong>
                  </li>
                  <li
                    className="slide-horiz-item amenity-toggle-item"
                    onClick={() => {
                      this.showFood = !this.showFood ? true : false;
                      this.forceUpdate();
                      G.ToggleMarkers("foodAmenityMarkers", this.showFood);
                    }}
                  >
                    <strong>
                      {!this.showFood ? "show" : "hide"} food & drink
                    </strong>
                  </li>
                  <li
                    className="slide-horiz-item amenity-toggle-item"
                    onClick={() => {
                      this.showHealth = !this.showHealth ? true : false;
                      this.forceUpdate();
                      G.ToggleMarkers("wellAmenityMarkers", this.showHealth);
                    }}
                  >
                    <strong>
                      {!this.showHealth ? "show" : "hide"} fitness & health
                    </strong>
                  </li>
                  <li
                    className="slide-horiz-item amenity-toggle-item"
                    onClick={() => {
                      this.showHotels = !this.showHotels ? true : false;
                      this.forceUpdate();
                      G.ToggleMarkers("hotelAmenityMarkers", this.showHotels);
                    }}
                  >
                    <strong>{!this.showHotels ? "show" : "hide"} hotels</strong>
                  </li>
                </ul>
              </div>
            </div>

            <ul className="slide-horiz-nav w-list-unstyled">
              <li
                className="slide-horiz-item"
                onClick={() => this.props.GoToState("ELIZABETH")}
              >
                elizabeth line
              </li>
              <li
                className="slide-horiz-item"
                onClick={() => this.props.GoToState("RAIL")}
              >
                national rail
              </li>
              <li
                className="slide-horiz-item"
                onClick={() => this.props.GoToState("UNDERGROUND")}
              >
                underground
              </li>
            </ul>
          </div>
          <div
            ref={(ref) => (this.slideBg = ref)}
            className="location-panel-bg swipe-init"
          ></div>
        </div>
      </div>
    );
  }
}

export default Location;
