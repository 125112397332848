import * as THREE from "three";
import Entity from "../Entity";
import Loop from "../Loop";
import { G } from "../../globals";
import { CONFIG } from "../../config";
import U from "../../utils";

if (!G.featureMarkers) G.featureMarkers = [];

class FeatureMarker extends Entity {
	constructor(params) {
		super();

		this.marker = new THREE.Group();
		this.markerVLookGroup = new THREE.Group();
		this.markerLookGroup = new THREE.Group();
		this.markerLabelGroup = new THREE.Group();
		this.markerStaticGroup = new THREE.Group();
		this.SetActiveAppearance = this.SetActiveAppearance.bind(this);
		this.ResetMarkerAppearance = this.ResetMarkerAppearance.bind(this);
		this.ScaleWithZoom = this.ScaleWithZoom.bind(this);

		G.featureMarkers.push(this);

		this.scaleRate = {
			//make markers larger above a certain zoom level
			grow: {
				//active above this level
				minZoom:
					params.scaleRate && params.scaleRate.grow && params.scaleRate.grow.minZoom
						? params.scaleRate.grow.minZoom
						: 400,
				//reach max size by this zoom level
				maxZoom:
					params.scaleRate && params.scaleRate.grow && params.scaleRate.grow.maxZoom
						? params.scaleRate.grow.maxZoom
						: 1000,
				//target scale at max zoom - in other words start at 1x scale at min zoom, and go to 4x scale at max zoom
				rate:
					params.scaleRate && params.scaleRate.grow && params.scaleRate.grow.rate
						? params.scaleRate.grow.rate
						: 3,
			},
			//make markers smaller below a certain zoom level
			shrink: {
				//shrink below this level
				maxZoom:
					params.scaleRate && params.scaleRate.shrink && params.scaleRate.shrink.maxZoom
						? params.scaleRate.shrink.maxZoom
						: 70,
				//get to min size by this point
				minZoom:
					params.scaleRate && params.scaleRate.shrink && params.scaleRate.shrink.minZoom
						? params.scaleRate.shrink.minZoom
						: 30,
				//target min scale
				rate:
					params.scaleRate && params.scaleRate.shrink && params.scaleRate.shrink.rate
						? params.scaleRate.shrink.rate
						: 0.2,
			},
		};

		this.proximityDist = params.proximityDist ? params.proximityDist : { max: 300, min: 200 };
		// document.addEventListener("popupWillClose", FeatureMarker.ResetAllMarkers);

		this.params = params;

		this.isActive = false;

		const icon = G.loadedAssets[params.markerData.icon];
		const iconSize = U.SetProportionalSize(icon.image.width, icon.image.height, 8, "height");

		this.icon = new THREE.Mesh(
			new THREE.PlaneGeometry(iconSize.width, iconSize.height),
			new THREE.MeshBasicMaterial({
				color: new THREE.Color("#ffffff"),
				map: icon,
				transparent: true,
				toneMapped: false,
			})
		);

		this.icon.material.map.encoding = THREE.sRGBEncoding;
		this.icon.material.map.anisotropy = G.renderer.getMaxAnisotropy();
		this.icon.material.map.magFilter = THREE.LinearMipmapLinearFilter;
		this.icon.material.map.minFilter = THREE.LinearMipmapLinearFilter;
		this.icon.material.needsUpdate = true;

		this.iconHover = G.loadedAssets[this.params.markerData.iconHover];
		this.iconHover.encoding = THREE.sRGBEncoding;
		this.iconHover.anisotropy = G.renderer.getMaxAnisotropy();
		this.iconHover.magFilter = THREE.LinearMipmapLinearFilter;
		this.iconHover.minFilter = THREE.LinearMipmapLinearFilter;

		this.markerLabelGroup.add(this.icon);

		this.icon.position.set(0, 0, 0);

		const interactionPlaneMeshSize = {
			width: iconSize.width + 3,
			height: iconSize.height + 3,
		};

		this.interactionPlaneMesh = new THREE.Mesh(
			new THREE.PlaneGeometry(
				interactionPlaneMeshSize.width,
				interactionPlaneMeshSize.height
			),
			new THREE.MeshBasicMaterial({
				color: new THREE.Color("green"),
				// depthTest: true,
				transparent: true,
				opacity: 0,
			})
		);

		this.interactionPlaneMesh.position.set(0, 0, 0.2);

		this.markerLabelGroup.add(this.interactionPlaneMesh);
		this.markerVLookGroup.add(this.markerStaticGroup);
		this.markerVLookGroup.add(this.markerLabelGroup);
		this.markerLookGroup.add(this.markerVLookGroup);

		this.marker.add(this.markerLookGroup);

		this.enableInteraction(this.interactionPlaneMesh, 2);

		this.loop = new Loop(() => {
			if (this.marker.visible) {
				// this.ScaleWithZoom();
				this.RotateToFaceCamera();
			}
		}).start();

		const zOffset = 0;

		setTimeout(() => {
			this.markerLabelGroup.position.set(0, iconSize.height / 2 - 1.5, 0);
			this.marker.position.set(
				params.markerData.location.x,
				params.markerData.height * params.forceScale,
				params.markerData.location.z
			);

			this.marker.scale.set(
				this.marker.scale.x * params.forceScale,
				this.marker.scale.y * params.forceScale,
				this.marker.scale.z * params.forceScale
			);

			this.markerVLookGroup.position.set(
				this.markerVLookGroup.position.x,
				this.markerVLookGroup.position.y,
				this.markerVLookGroup.position.z
			);
		}, 300);
	}

	onClick() {
		if (this.params.markerData.data && this.isVisible) {
			if (!this.isActive) {
				G.GoToState(this.params.markerData.data);
				// FeatureMarker.ResetAllMarkers();
				// this.SetActiveAppearance();
				// this.params.markerData.data.type !== "state" ? G.OpenOverlay(this.params.markerData.data.type, this.params.markerData.data.content) : G.GoToState(this.params.markerData.data.content);
			} else {
				// this.params.markerData.data.type !== "state" ? G.CloseOverlay(this.params.markerData.data.type) : G.GoToState(this.params.markerData.data.origin);
				// this.ResetMarkerAppearance();
			}
		}
	}

	ScaleWithZoom() {
		const d = this.marker.position.distanceTo({
			x: G.cam.camera.position.x,
			y: 0,
			z: G.cam.camera.position.z,
		});
		let scale = 1;
		if (d > this.proximityDist.min && G.cam.camera.zoomLevel < this.scaleRate.grow.minZoom) {
			scale = U.Clamp(
				U.MapRange(d, this.proximityDist.max, this.proximityDist.min, 0.1, 1),
				0.1,
				1
			);
		} else if (G.cam.camera.zoomLevel > this.scaleRate.grow.minZoom) {
			scale = U.MapRange(
				G.cam.camera.zoomLevel,
				this.scaleRate.grow.minZoom,
				this.scaleRate.grow.maxZoom,
				1,
				this.scaleRate.grow.rate
			);
			if (scale < 1) scale = 1;
		} else if (G.cam.camera.zoomLevel < this.scaleRate.shrink.maxZoom) {
			scale = U.MapRange(
				G.cam.camera.zoomLevel,
				this.scaleRate.shrink.minZoom,
				this.scaleRate.shrink.maxZoom,
				this.scaleRate.shrink.rate,
				1
			);
		}

		this.markerLookGroup.scale.set(scale, scale, scale);
	}

	onMouseEnter() {
		if (this.isVisible) {
			document.body.style.cursor = "pointer";
			if (this.params.markerData.iconHover)
				this.icon.material.map = G.loadedAssets[this.params.markerData.iconHover];
		}
	}

	onMouseLeave() {
		document.body.style.cursor = "auto";
		if (!this.isActive && this.params.markerData.iconHover)
			this.icon.material.map = G.loadedAssets[this.params.markerData.icon];
	}

	SetActiveAppearance() {
		// this.isActive = true;
		// if (this.params.markerData.iconHover) {
		// 	this.icon.material.map = G.loadedAssets[this.params.markerData.iconHover];
		// }
	}

	ResetMarkerAppearance() {
		// this.isActive = false;
		// this.icon.material.map = G.loadedAssets[this.params.markerData.icon];
	}

	RotateToFaceCamera() {
		if (G.cam.camera.getPolarAngle && G.cam.camera.getPolarAngle() < 0.25) {
			this.markerLookGroup.rotation.set(-Math.PI / 2, 0, G.cam.camera.getAzimuthalAngle());
			this.markerVLookGroup.rotation.set(0, 0, 0);
		} else if (G.cam.camera.getPolarAngle) {
			this.markerLookGroup.rotation.set(0, G.cam.camera.getAzimuthalAngle(), 0);
			this.markerVLookGroup.rotation.set(
				-U.MapRange(G.cam.camera.getPolarAngle(), 0, Math.PI / 2, Math.PI / 2, 0),
				0,
				0
			); //polar angle = 1.5 (half pi) -  angle should be 0, polar angle = 0 - angle should be 1.5 (or half pi)
			// this.markerLookGroup.lookAt(G.cam.camera.position);
		}
	}

	static ResetAllMarkers() {
		G.featureMarkers.map((m) => m.ResetMarkerAppearance());
	}
}

export default FeatureMarker;
