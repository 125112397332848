import React, { Component } from "react";
import { G } from "./globals";
import { CONFIG } from "./config";
import { STATE } from "./data/states";
import Debug from "./Debug";
import Viz from "./viz/Viz";
import UI from "./components/UI";

class App extends Component {
  constructor() {
    super();

    this.GoToState = this.GoToState.bind(this);
    this.popstateEventHandler = this.popstateEventHandler.bind(this);

    this.Init = this.Init.bind(this);
    G.GoToState = this.GoToState;

    this.firstInit = true;

    this.state = {
      current: STATE.INIT,
      currentIndex: 0,
      previous: null,
    };

    // document.addEventListener("loadDone", this.Init);
    // window.addEventListener("popstate", this.popstateEventHandler);
  }

  popstateEventHandler() {
    this.Init();
  }

  componentDidMount() {
    setTimeout(() => {
      if (!CONFIG.d_enableDebug) {
        this.Init();
      } else this.GoToState("DEBUG");
    }, 10);
    this.mainDiv.addEventListener("gesturestart", this.DisableZoomEvents);
  }

  componentDidUpdate() {
    //FIRE OFF EVENT!
    this.FireEvent();
  }

  Init() {
    console.log(window.location.hash);
    setTimeout(() => {
      if (window.location.hash !== "") {
        const dest = window.location.hash
          .slice(1, window.location.hash.length)
          .toUpperCase();
        console.log(dest);
        if (STATE[dest] !== undefined) {
          // console.log("<<<<<<<<<<<< I GET HERE >>>>>>>>>>>>>>");
          this.GoToState(dest, true);
        } else this.GoToState("OVERVIEW");
      } else this.GoToState("OVERVIEW");

      if (this.firstInit) {
        window.addEventListener("popstate", this.popstateEventHandler);
        this.firstInit = false;
      }
    }, 100);
  }

  GoToState(p, interrupt = false) {
    console.log("GOING TO >>>>>");
    console.log(p);

    if (
      (typeof p === "string" &&
        STATE[p.toUpperCase()] &&
        STATE[p.toUpperCase()].name === this.state.current.name &&
        !interrupt) ||
      (typeof p === "number" &&
        STATE[p] &&
        STATE[p].name === this.state.current.name &&
        !interrupt)
    )
      return;

    return new Promise((resolve, reject) => {
      let newState;

      if (G.inTransition && !interrupt) return;

      if (typeof p === "string") {
        if (!STATE[p.toUpperCase()]) {
          console.error("INVALID STATE ON GOTOSTATE: " + p);
          return;
        } else newState = STATE[p.toUpperCase()];
      }
      G.currentState = newState;
      G.previousState = this.state.current;

      //only set hash if ui is present
      if (newState.ui && !CONFIG.d_enableDebug)
        window.location.hash = `#${newState.name.toLowerCase()}`;

      newState.interrupt = interrupt;

      this.FireOutroEvent(newState);

      setTimeout(() => {
        this.setState({
          current: newState,
          currentIndex:
            newState.index !== undefined
              ? newState.index
              : this.state.currentIndex,
          previous: this.state.current,
        });
        if (newState.onStateLoad) newState.onStateLoad();
        resolve(newState);
      }, 600);
    });
  }

  FireEvent() {
    // console.log('EVENT!');
    this.eventDelegate = new CustomEvent("stateChange", {
      bubbles: true,
      detail: { state: this.state.current },
    });
    document.dispatchEvent(this.eventDelegate);
  }

  FireOutroEvent(newState) {
    this.eventDelegate = new CustomEvent("stateWillChange", {
      bubbles: true,
      detail: { newState: newState },
    });
    document.dispatchEvent(this.eventDelegate);
  }

  DisableZoomEvents(e) {
    e.preventDefault();
  }

  render() {
    return (
      <div ref={(ref) => (this.mainDiv = ref)} className="app">
        <div className="interface no-event">
          <Debug currentState={this.state.current} GoToState={this.GoToState} />
          {CONFIG.d_enableUI ? (
            <UI
              currentState={this.state.current}
              previousState={this.state.previous}
              GoToState={this.GoToState}
              NextState={this.NextState}
              GetNextState={this.GetNextState}
              data={this.props.data}
            />
          ) : null}
        </div>
        {<Viz />}
      </div>
    );
  }
}

export default App;
