import * as THREE from 'three';

export const elizabethLineTextData = [
	// {
	// 	firstLineText: "Crossrail on your doorstep",
	// 	firstLineColor: "#FFCF40",
	// 	firstLineSize: 5,
	// 	secondLineText: "Arriving soon",
	// 	secondLineColor: "",
	// 	secondLineSize: 5,
	// 	image: null,
	// 	location: { x: 34.873, y: 0, z: 13.289 },
	// 	rotation: -47.79,
	// 	height: 0,
	// },
	{
		firstLineText: "",
		firstLineColor: "#FFCF40",
		firstLineSize: 5,
		secondLineText: "",
		secondLineColor: "",
		secondLineSize: 5,
		image: "elizabethBlurbv2",
		imageSize: { width: 35 * 2, height: 50 * 2 },
		location: { x: -259.201, y: 0, z: 215.943 },
		rotation: -47.79,
		height: 0,
	},
	// {
	// 	firstLineText: "",
	// 	firstLineColor: "#FFCF40",
	// 	firstLineSize: 5,
	// 	secondLineText: "",
	// 	secondLineColor: "",
	// 	secondLineSize: 5,
	// 	image: "elizabethBlurb",
	// 	imageSize: { width: 32 * 2, height: 6 * 2 },
	// 	location: { x: 34.873, y: 0, z: 13.289 },
	// 	rotation: -47.79,
	// 	height: 0,
	// },
	// {
	// 	firstLineText: "Farringdon",
	// 	firstLineColor: "#A166F4",
	// 	firstLineSize: 3,
	// 	secondLineText: "8 mins",
	// 	secondLineColor: "",
	// 	secondLineSize: 3,
	// 	image: null,
	// 	location: { x: -77.97, y: 0, z: 85.166 },
	// 	rotation: -47.79,
	// 	height: 0,
	// },
	// {
	// 	firstLineText: "Canary Wharf",
	// 	firstLineColor: "#A166F4",
	// 	firstLineSize: 3,
	// 	secondLineText: "12 mins",
	// 	secondLineColor: "",
	// 	secondLineSize: 3,
	// 	image: null,
	// 	location: { x: -134.027, y: 0, z: 119.472 },
	// 	rotation: -47.79,
	// 	height: 0,
	// },
	// {
	// 	firstLineText: "Stratford",
	// 	firstLineColor: "#A166F4",
	// 	firstLineSize: 3,
	// 	secondLineText: "14 mins",
	// 	secondLineColor: "",
	// 	secondLineSize: 3,
	// 	image: null,
	// 	location: { x: -176.592, y: 0, z: 148.8235 },
	// 	rotation: -47.79,
	// 	height: 0,
	// },
	// {
	// 	firstLineText: "Heathrow Airport",
	// 	firstLineColor: "#A166F4",
	// 	firstLineSize: 3,
	// 	secondLineText: "24 mins",
	// 	secondLineColor: "",
	// 	secondLineSize: 3,
	// 	image: null,
	// 	location: { x: 124.338, y: 0, z: -47.747 },
	// 	rotation: -47.79,
	// 	height: 5,
	// },
	// {
	// 	firstLineText: "Bond Street",
	// 	firstLineColor: "#A166F4",
	// 	firstLineSize: 3,
	// 	secondLineText: "2 mins",
	// 	secondLineColor: "",
	// 	secondLineSize: 3,
	// 	image: null,
	// 	location: { x: -16.216, y: 0, z: 46.503 },
	// 	rotation: -47.79,
	// 	height: 5,
	// },
];

export const undergroundTravelTimes = [
	{
		firstLineText: "",
		firstLineColor: "",
		firstLineSize: 5,
		secondLineText: "",
		secondLineColor: "",
		secondLineSize: 5,
		image: "undergroundTravelTimes",
		imageSize: { width: 18 * 2, height: 35 * 2 },
		location: { x: -50.069 + -23, y: 0, z: 55.948 + 28.5 },
		rotation: -47.79,
		height: 5,
	},
];

export const trainsTravelTimes = [
	{
		firstLineText: "",
		firstLineColor: "",
		firstLineSize: 5,
		secondLineText: "",
		secondLineColor: "",
		secondLineSize: 5,
		image: "trainsTravelTimes",
		imageSize: { width: 15.5 * 1.4, height: 25.6 * 1.6 },
		location: {x: 104.925, y: 0, z: 408.567},
		rotation: 42.4,
		height: 7,
	},
];

export const walkRadiusData = [
	{
		firstLineText: "",
		firstLineColor: "",
		firstLineSize: 5,
		secondLineText: "",
		secondLineColor: "",
		secondLineSize: 5,
		image: "walkRadius",
		imageSize: { width: 270, height: 270 },
		location: { x: 349.5 + 22, y: 0, z: 876.5 + 34.5},
		rotation: 0,
		height: 3.5,
	},
];
