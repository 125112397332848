import React, { Component } from "react";
import { floorsData, GetFloor } from "../data/floorsData";
import { G } from "../globals";
import U from "../utils";

class Floors extends Component {
  constructor(props) {
    super();

    this.showFloor = this.showFloor.bind(this);

    this.state = {
      currentFloor: floorsData.find((f) => f.state === props.currentState.name),
    };
  }

  showFloor(fl) {
    const floor = GetFloor(fl);
    if (floor && floor.isLet) return;
    this.props.GoToState(floor.state);
    setTimeout(() => {
      this.setState({ currentFloor: floor });
    }, 400);
  }

  nextFloorPlan() {
    if (this.state.currentFloor.index + 1 > floorsData.length - 1) return;
    this.ShowFloor(floorsData[this.state.currentFloor.index + 1].name);
  }

  prevFloorPlan() {
    if (this.state.currentFloor.index - 1 < 0) return;
    this.ShowFloor(floorsData[this.state.currentFloor.index - 1].name);
  }

  render() {
    console.log(this.state.currentFloor);
    return (
      <div className="slide floor-select">
        <div className="slide-close-container">
          <div
            className="slide-close clickable"
            onClick={() => this.props.GoToState("BUILDING")}
          ></div>
        </div>
        <div className="floor-content clickable">
          <div className="floor-title-wrapper">
            <div className="floor-arrows hidden">
              <div className="floor-arrow up-arrow"></div>
              <div className="floor-arrow down-arrow"></div>
            </div>
            <div className="floor-title-area">
              <h2 className="floor-title">
                {this.state.currentFloor.name.toLowerCase()} floor
              </h2>
              <div
                className={`floor-area ${
                  !this.state.currentFloor.sqft ? "hidden" : ""
                }`}
              >
                {this.state.currentFloor.sqft
                  ? U.FormatNumber(this.state.currentFloor.sqft)
                  : null}{" "}
                sq ft
              </div>
            </div>
          </div>
          <ul role="list" className="floor-selector-list w-list-unstyled">
            {floorsData.map((floor, i) => {
              return (
                <li
                  key={i}
                  className={`floor-selector-item ${
                    this.props.currentState.name === floor.state
                      ? "floor-selector-item-active"
                      : ""
                  }
                  ${floor.isLet ? "floor-let" : ""}`}
                  onClick={() => this.showFloor(floor.name)}
                >
                  <div className="floor-selector-title">
                    {floor.name}
                    {floor.hasTerrace ? (
                      <span className="floor-selector-sub"> (T)</span>
                    ) : (
                      ""
                    )}
                    <span
                      className={`floor-red-label ${
                        floor.images.length >= 1 ? "" : "hidden"
                      }`}
                    />
                  </div>
                  <div
                    className={`floor-selector-title ${
                      !floor.sqft ? "hidden" : ""
                    }`}
                  >
                    {floor.sqft ? U.FormatNumber(floor.sqft) : null}{" "}
                    <span className="floor-selector-sub">
                      {typeof floor.sqft === "number" ? "sq ft" : ""}
                    </span>
                  </div>
                </li>
              );
            })}
          </ul>
          <div
            className="text-link"
            onClick={() => G.showOverlay("showPlan", this.state.currentFloor)}
          >
            view floor plan
          </div>
        </div>
      </div>
    );
  }
}

export default Floors;
