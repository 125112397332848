import * as THREE from "three";
import { FontLoader } from "three/examples/jsm/loaders/FontLoader";
import { G } from "../globals";
import Entity from "./Entity";
import { STYLE } from "../config";
import StreetMarker from "./markers/streetMarker";
import FeatureMarker from "./markers/featureMarker";
import HighlightMarker from "./markers/highlightMarker";
import FloorImageMarker from "./markers/floorImageMarker";
import AmenityMarker from "./markers/amenityMarker";
// import ConnectionTravelMarker from "./markers/connectionTravelMarker";
// import ConnectionElizabethMarker from "./markers/connectionElizabethMarker";
import LargeTextMarker from "./markers/largeTextMarker";
import { featureMarkers } from "../data/markerData";
import { floorsData } from "../data/floorsData";
import { streetMarkerData, justSpringStreet } from "../data/streetMarkerData";
import { foodMarkers, wellMarkers, occupierMarkers, hotelMarkers } from "../data/amenityMarkerData";
import {
	basicTransportMarkers,
	connectionsMarkers,
	poiMarkers,
	busMarkers,
	bikeMarkers,
	elizabethStationMarker,
} from "../data/highlightMarkerData";
import {
	elizabethLineTextData,
	undergroundTravelTimes,
	trainsTravelTimes,
	walkRadiusData,
} from "../data/largeTextMarkerData";

class MarkerController extends Entity {
	constructor() {
		super();

		const textureLoader = new THREE.TextureLoader();
		const fontLoader = new FontLoader();

		this.ToggleMarkers = this.ToggleMarkers.bind(this);

		G.ToggleMarkers = this.ToggleMarkers;
		G.ShowSpecificMarker = this.ShowSpecificMarker;

		this.markers = {};

		this.markersToCreate = [
			{
				name: "featureMarkers",
				data: featureMarkers,
				marker: FeatureMarker,
				forceScale: 1,
			},
			{
				name: "streetMarkers",
				data: streetMarkerData,
				marker: StreetMarker,
			},
			{
				name: "justSpringStreetMarker",
				data: justSpringStreet,
				marker: StreetMarker,
			},
			{
				name: "elizabethStationMarker",
				data: elizabethStationMarker,
				icon: "elizabethTubeIcon",
				pinpointLine: false,
				color: "#ffffff",
				scaleWithZoom: true,
				scaleRate: 100,
				forceScale: 8,
				iconSize: { width: 2.25 * 1.5, height: 1.75 * 1.5 },
				marker: HighlightMarker,
			},
			{
				name: "busMarkers",
				data: busMarkers,
				icon: "busIcon",
				pinpointLine: true,
				scaleWithZoom: true,
				scaleRate: 150,
				forceScale: 5,
				iconSize: { width: 3, height: 3 },
				marker: HighlightMarker,
			},
			{
				name: "bikeMarkers",
				data: bikeMarkers,
				icon: "bikeIcon",
				pinpointLine: true,
				scaleWithZoom: true,
				scaleRate: 150,
				forceScale: 5,
				iconSize: { width: 3, height: 3 },
				marker: HighlightMarker,
			},
			{
				name: "poiMarkers",
				data: poiMarkers,
				pinpointLine: true,
				scaleWithZoom: true,
				scaleRate: 120,
				marker: HighlightMarker,
			},
			{
				name: "basicTransportMarkers",
				data: basicTransportMarkers,
				icon: "trainIcon",
				pinpointLine: false,
				scaleWithZoom: true,
				scaleRate: 120,
				iconSize: { width: 2.25, height: 1.752 },
				iconOffset: { x: 0, y: -0.25 },
				marker: HighlightMarker,
			},
			{
				name: "connectionsMarkers",
				data: connectionsMarkers,
				icon: "tubeIcon",
				pinpointLine: true,
				scaleWithZoom: true,
				scaleRate: 120,
				forceScale: 1,
				iconSize: { width: 2.25, height: 1.75 },
				iconOffset: { x: 0, y: -0.25 },
				marker: HighlightMarker,
			},
			{
				name: "textElizabethMarkers",
				data: elizabethLineTextData,
				marker: LargeTextMarker,
			},
			{
				name: "undergroundTravelTimes",
				data: undergroundTravelTimes,
				marker: LargeTextMarker,
			},
			{
				name: "trainsTravelTimes",
				data: trainsTravelTimes,
				marker: LargeTextMarker,
			},
			{
				name: "walkRadius",
				data: walkRadiusData,
				marker: LargeTextMarker,
			},
			{
				name: "foodAmenityMarkers",
				data: foodMarkers,
				marker: AmenityMarker,
				icon: "foodMarkerIcon",
				iconSize: { width: 3, height: 4 },
				iconActive: "foodMarkerIconActive",
				forceScale: 6,
			},
			{
				name: "wellAmenityMarkers",
				data: wellMarkers,
				marker: AmenityMarker,
				icon: "wellMarkerIcon",
				iconSize: { width: 3, height: 4 },
				iconActive: "wellMarkerIconActive",
				forceScale: 6,
			},
			{
				name: "hotelAmenityMarkers",
				data: hotelMarkers,
				marker: AmenityMarker,
				icon: "hotelMarkerIcon",
				iconSize: { width: 3, height: 4 },
				iconActive: "hotelMarkerIconActive",
				forceScale: 6,
			},
			{
				name: "occupierAmenityMarkers",
				data: occupierMarkers,
				marker: AmenityMarker,
				icon: "occupiersMarkerIcon",
				iconSize: { width: 3, height: 4 },
				iconActive: "occupiersMarkerIconActive",
				forceScale: 6,
			},
		];

		//add floor image markers
		const imagesToMarker = floorsData
			.filter((floor) => floor.images.length > 0)
			.map((floor) => {
				return { floor: floor.state, images: floor.images };
			});

		imagesToMarker.map((floorImages) => {
			this.markersToCreate.push({
				name: `${floorImages.floor.toLowerCase()}-floor`,
				data: floorImages.images,
				marker: FloorImageMarker,
				icon: "floorImageMarker",
				iconActive: "floorImageMarkerHover",
				iconSize: { width: 2, height: 2 },
			});
			return null;
		});

		this.assetsManifest = [
			{ name: "font", loader: fontLoader, path: `fonts/circularBold.json` },
			{ name: "featureHealthy", loader: textureLoader, path: "images/feature-healthy.png" },
			{ name: "featureQuality", loader: textureLoader, path: "images/feature-quality.png" },
			{
				name: "featureResponsible",
				loader: textureLoader,
				path: "images/feature-responsible.png",
			},
			{
				name: "featureConnected",
				loader: textureLoader,
				path: "images/feature-connected.png",
			},
			{ name: "featureSmart", loader: textureLoader, path: "images/feature-smart.png" },
			{
				name: "featureHealthyHover",
				loader: textureLoader,
				path: "images/feature-healthy-hover.png",
			},
			{
				name: "featureQualityHover",
				loader: textureLoader,
				path: "images/feature-quality-hover.png",
			},
			{
				name: "featureResponsibleHover",
				loader: textureLoader,
				path: "images/feature-responsible-hover.png",
			},
			{
				name: "featureConnectedHover",
				loader: textureLoader,
				path: "images/feature-connected-hover.png",
			},
			{
				name: "featureSmartHover",
				loader: textureLoader,
				path: "images/feature-smart-hover.png",
			},
			{
				name: "floorImageMarker",
				loader: textureLoader,
				path: `images/floor-image-icon.png`,
			},
			{
				name: "floorImageMarkerHover",
				loader: textureLoader,
				path: `images/floor-image-icon-hover.png`,
			},
			{ name: "foodMarkerIcon", loader: textureLoader, path: `images/food-icon.png` },
			{
				name: "foodMarkerIconActive",
				loader: textureLoader,
				path: `images/food-icon-active.png`,
			},
			{ name: "wellMarkerIcon", loader: textureLoader, path: `images/well-icon.png` },
			{
				name: "wellMarkerIconActive",
				loader: textureLoader,
				path: `images/well-icon-active.png`,
			},
			{ name: "hotelMarkerIcon", loader: textureLoader, path: `images/hotel-icon.png` },
			{
				name: "hotelMarkerIconActive",
				loader: textureLoader,
				path: `images/hotel-icon-active.png`,
			},
			{ name: "occupiersMarkerIcon", loader: textureLoader, path: `images/occ-icon.png` },
			{
				name: "occupiersMarkerIconActive",
				loader: textureLoader,
				path: `images/occ-icon-active.png`,
			},
			{
				name: "elizabethTubeIcon",
				loader: textureLoader,
				path: `images/elizabeth-tube-icon.png`,
			},
			{ name: "elizabethBlurb", loader: textureLoader, path: `images/elizabeth-blurb.png` },
			{ name: "elizabethBlurbv2", loader: textureLoader, path: `images/elizabeth-v2.png` },
			{ name: "busIcon", loader: textureLoader, path: `images/bus-icon.png` },
			{ name: "bikeIcon", loader: textureLoader, path: `images/bike-icon.png` },
			{
				name: "undergroundTravelTimes",
				loader: textureLoader,
				path: `images/underground-travel-times.png`,
			},
			{
				name: "trainsTravelTimes",
				loader: textureLoader,
				path: `images/trains-travel-times.png`,
			},
			{ name: "walkRadius", loader: textureLoader, path: `images/walk-radius.png` },
			{ name: "trainIcon", loader: textureLoader, path: `images/train-icon.png` },
			{ name: "tubeIcon", loader: textureLoader, path: `images/tube-icon.png` },
		];

		this.CreateMarkers();
		setTimeout(() => {
			this.HideMarkers();
		}, 3000);
	}

	CreateMarkers() {
		this.LoadAssets().then((loadedAssets) => {
			this.loadedAssets = loadedAssets;
			G.loadedAssets = loadedAssets;
			this.markersToCreate.map((newMarker) => {
				newMarker.groupName = `${newMarker.name}Group`;
				newMarker.group = new THREE.Group();
				this.markers[newMarker.groupName] = newMarker;

				if (newMarker.data && Array.isArray(newMarker.data)) {
					newMarker.m = newMarker.data.map((entry, i) => {
						const m = new newMarker.marker({
							font: loadedAssets.font ? loadedAssets.font : null,
							name: newMarker.name,
							id: entry.id ? entry.id : null,
							markerData: entry,
							image: loadedAssets[entry.image],
							margin: newMarker.margin,
							scaleWithZoom: newMarker.scaleWithZoom
								? newMarker.scaleWithZoom
								: false,
							scaleRate: newMarker.scaleRate ? newMarker.scaleRate : 150,
							height: newMarker.height
								? newMarker.height
								: entry.height
								? entry.height
								: 6,
							pinpointLine: entry.pinpointLine
								? entry.pinpointLine
								: newMarker.pinpointLine,
							iconSize: newMarker.iconSize
								? newMarker.iconSize
								: entry.iconSize
								? entry.iconSize
								: null,
							icon: loadedAssets[newMarker.icon]
								? loadedAssets[newMarker.icon]
								: null,
							iconActive: loadedAssets[newMarker.iconActive]
								? loadedAssets[newMarker.iconActive]
								: null,
							iconOffset: newMarker.iconOffset
								? newMarker.iconOffset
								: { x: 0, y: 0 },
							thumbImg: entry.thumbImg ? loadedAssets[entry.thumbImg] : null,
							activeIcon: loadedAssets[newMarker.activeIcon],
							occupiers: entry.occupiers
								? entry.occupiers.map((o) => {
										const img = loadedAssets[o];
										if (!img) console.error("CAN'T LOAD ASSET " + o);
										return img;
								  })
								: null,
							color: newMarker.color,
							textColor: newMarker.textColor,
							activeColor: newMarker.activeColor,
							activeTextColor: newMarker.activeTextColor,
							showMode: newMarker.showMode ? newMarker.showMode : null,
							proximityDist: newMarker.proximityDist ? newMarker.proximityDist : null,
							forceScale: newMarker.forceScale
								? newMarker.forceScale
								: entry.forceScale
								? entry.forceScale
								: 1,
						});
						newMarker.group.add(m.marker);
						newMarker.group.isVisible = false;
						return m;
					});
				} else {
					// Check if marker is a promise by seeing if it has a then function (eg if we are loading models);
					newMarker.m = new newMarker.marker({
						font: loadedAssets.font ? loadedAssets.font : null,
						name: newMarker.name,
					}).then((loadedMarker) => {
						newMarker.group.add(loadedMarker);
						return loadedMarker;
					});
				}

				G.markers[newMarker.name] = newMarker;
				this.Instantiate(newMarker.group, newMarker.groupName, false, true);
				return null;
			});
		});
	}

	LoadAssets() {
		const promiseArray = this.assetsManifest.map((asset) => {
			return new Promise((resolve, reject) => {
				asset.loader.load(asset.path, (data) => {
					resolve(data);
				});
			});
		});

		return Promise.all(promiseArray).then((loadedAssets) => {
			const assets = {};
			this.assetsManifest.map((asset, i) => {
				assets[asset.name] = loadedAssets[i];
				return null;
			});

			return assets;
		});
	}

	onStateChange(newState) {
		this.HideMarkers();
	}
	onCamDone(newState) {
		if (newState.markers) {
			this.ToggleMarkers(newState.markers, true);
		}
	}

	HideMarkers() {
		[...Object.values(this.markers)].map((marker) => {
			if (Array.isArray(G.markers[marker.name].m))
				G.markers[marker.name].m.map((m) => (m.isVisible = false));
			G.markers[marker.name].group.visible = false;
			return null;
		});
	}

	ToggleMarkers(markersToToggle, bool = true) {
		if (Array.isArray(markersToToggle)) {
			markersToToggle.map((marker) => {
				if (G.markers[marker]) {
					G.markers[marker].group.visible = bool;
					if (Array.isArray(G.markers[marker].m))
						G.markers[marker].m.map((m) => (m.isVisible = bool));
				} else console.error("UNABLE TO FIND MARKER WITH STRING - " + marker);
				return null;
			});
		} else {
			if (G.markers[markersToToggle]) {
				console.log(G.markers[markersToToggle]);
				G.markers[markersToToggle].group.visible = bool;
				if (Array.isArray(G.markers[markersToToggle].m))
					G.markers[markersToToggle].m.map((m) => (m.isVisible = bool));
			} else console.error("UNABLE TO FIND MARKER WITH STRING - " + markersToToggle);
			return null;
		}
	}

	ShowSpecificMarker(id, collection) {
		if (!G.markers[collection])
			return console.error("SHOW SPECIFIC MARKER: UNABLE TO FIND COLLECTION " + collection);

		this.ToggleMarkers(collection);
		const marker = G.markers[collection].m.find((bM) => bM.params.id === id);
		if (marker) {
			marker.isVisible = true;
			return marker;
		} else console.error("UNABLE TO FIND MARKER " + id + " IN COLLECTION " + collection);
	}
}

export default MarkerController;
