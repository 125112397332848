import React, { Component } from 'react';
import anime from 'animejs';
import { G } from '../globals';
import U from '../utils';


class OverlayPlan extends Component {

  constructor() {
		super();

		this.MouseMove = this.MouseMove.bind(this);
		this.MouseDown = this.MouseDown.bind(this);
		this.MouseUp = this.MouseUp.bind(this);

		this.state = {
			currentIndex: 0
		};

		this.params = {
			isBusy: false,
			zoom: {
				current: 1,
				max: 2,
				min: 0.6,
				default: 1,
				rate: 0.4
			},
			delta: {
				x: 0,
				y: 0
			},
			drag: {
				elem: null,
				x: 0,
				y: 0,
				state: false
			}
		};
	}

	componentDidMount() {
		this.params.drag.elem = this.planImage;
	}

	componentDidMount() {
		// if (prevProps.show && !this.props.show) this.SwitchPlan(0);
		if (this.props.content) {
			//bind event listeners
			if (U.GetResponsiveMode().isTouch) {
				this.planImage.addEventListener('touchmove', this.MouseMove);
				this.planImage.addEventListener('touchstart', this.MouseDown);
				document.addEventListener('touchend', this.MouseUp);
			} else {
				this.planImage.addEventListener('mousemove', this.MouseMove);
				this.planImage.addEventListener('mousedown', this.MouseDown);
				document.addEventListener('mouseup', this.MouseUp);
			}
		}
  }
  
  componentWillUnmount() {
			//clean up listener
			if (U.GetResponsiveMode().isTouch) {
				this.planImage.removeEventListener('touchmove', this.MouseMove);
				this.planImage.removeEventListener('touchstart', this.MouseDown);
				document.removeEventListener('touchend', this.MouseUp);
			} else {
				this.planImage.removeEventListener('mousemove', this.MouseMove);
				this.planImage.removeEventListener('mousedown', this.MouseDown);
				document.removeEventListener('mouseup', this.MouseUp);
			}
  }

	SwitchPlan(index) {
		this.setState({ currentIndex: index });
	}

	MouseMove(e) {
		if (this.params.drag.state) {
			const pos = {
				x: U.GetResponsiveMode().isTouch ? e.touches[0].pageX : e.pageX,
				y: U.GetResponsiveMode().isTouch ? e.touches[0].pageY : e.pageY
			};
			this.params.delta.x = pos.x - this.params.drag.x;
			this.params.delta.y = pos.y - this.params.drag.y;

			const imagePanStyle = {
				left: this.params.drag.elem.style.left.substring(0, this.params.drag.elem.style.left.length - 2),
				top: this.params.drag.elem.style.top.substring(0, this.params.drag.elem.style.top.length - 2)
			};

			this.params.drag.elem.style.left = `${parseInt(imagePanStyle.left) + this.params.delta.x}px`;
			this.params.drag.elem.style.top = `${parseInt(imagePanStyle.top) + this.params.delta.y}px`;

			this.params.drag.x = pos.x;
			this.params.drag.y = pos.y;

			this.params.drag.elem.style.cursor = 'grabbing';
		}
	}

	MouseDown(e) {
    const primaryButton = U.GetResponsiveMode().isTouch ? 0 : 1;
    console.log("CLICK");
		if (!this.params.drag.state && e.which === primaryButton) {
			if (e.target.classList[0] === this.planImage.classList[0]) this.params.drag.elem = this.planImage;
			else return;

			this.params.drag.x = U.GetResponsiveMode().isTouch ? e.touches[0].pageX : e.pageX;
			this.params.drag.y = U.GetResponsiveMode().isTouch ? e.touches[0].pageY : e.pageY;
			this.params.drag.state = true;
		}
		return false;
	}

	MouseUp(e) {
		if (this.params.drag.state) {
			this.params.drag.state = false;
			this.params.drag.elem.style.cursor = 'grab';
		}
	}

	ZoomIn() {
		if (this.params.isBusy) return;
		if (this.params.zoom.current + this.params.zoom.rate > this.params.zoom.max) return;
		this.params.isBusy = true;
		this.params.zoom.current = this.params.zoom.current + this.params.zoom.rate;
		this.ZoomAnim();
	}

	ZoomOut() {
		if (this.params.isBusy) return;
		if (this.params.zoom.current - this.params.zoom.rate < this.params.zoom.min) return;

		this.params.isBusy = true;
		this.params.zoom.current = this.params.zoom.current - this.params.zoom.rate;

		this.ZoomAnim();
	}

	ZoomReset() {
		if (this.params.isBusy) return;
		if (this.planImage === null) return;

		this.params.isBusy = true;
		this.params.zoom.current = this.params.zoom.default;

		anime({
			targets: this.planImage,
			top: '0px',
			left: '0px',
			easing: 'easeInOutQuad',
			duration: 200,
			complete: () => {
				if (this.planImage) {
					this.planImage.style.top = '0px';
					this.planImage.style.left = '0px';
				}
			}
		});

		this.ZoomAnim();
	}

	ZoomAnim() {
		anime({
			targets: this.planImage,
			scale: this.params.zoom.current,
			duration: 200,
			easing: 'easeOutQuad',
			complete: () => {
				this.params.isBusy = false;
			}
		});
	}
  

  render() {
    const specsList = this.props.content.plans[this.state.currentIndex].specs;

    return <div className="overlay gallery-overlay clickable">
          <div className="plan-viewer">
            <div className="overlay-title-block">
              <div className="floorplan-title-block">
                <h2 className="floor-title">{this.props.content.name.toLowerCase()} floor</h2>
                <div className={`floor-area ${!this.props.content.sqft ? 'hidden' : ''}`}>{this.props.content.sqft ? U.FormatNumber(this.props.content.sqft) : null} sq ft</div>
                <div className="floorplan-download-link hidden">Download plan</div>
                <ul className={`specs-list w-list-unstyled ${specsList !== undefined &&
						specsList &&
						specsList.length > 0
							? ''
							: 'hidden'}`}>
                  {specsList !== undefined && specsList && specsList.length > 0 ? (
							specsList.map((s, i) => {
								return (
									<li key={i} className={`specs-item ${s.isTotal ? 'specs-item-total' : ''}`}>
										{s.text}
									</li>
								);
							})
						) : null}
                </ul>
              </div>
            </div>
            <div ref={ref => this.planImage = ref} className="plan-image" style={{
          backgroundImage: `url(./images/plans/${this.props.content.plans[this.state.currentIndex].img})`,
          position: 'relative',
						left: '0px',
						top: '0px'
					}}></div>
            <div className="floorplan-switch-wrapper">
              {this.props.content.plans.map((p, i) => {
						return (
							<div
								key={i}
								onClick={() => this.SwitchPlan(i)}
								className={`floorplan-switch-label ${this.state.currentIndex === i ? 'active' : ''}`}
							>
								{p.name}
							</div>
						);
					})}
            </div>
            <div className="floorplan-control-block">
              <div className="floorplan-control-button zoom-in" onClick={() => this.ZoomIn()}></div>
              <div className="floorplan-control-button zoom-out" onClick={() => this.ZoomOut()}></div>
              <div className="floorplan-control-button zoom-reset" onClick={() => this.ZoomReset()}></div>
            </div>
          </div>
          <div className="slide-close-container">
            <div className="slide-close" onClick={() => G.showOverlay('showPlan', false)}></div>
          </div>
          <div className="overlay-bg blue-bg"></div>
        </div>;
  }
}

export default OverlayPlan;