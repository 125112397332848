import U from "../utils";
import * as THREE from "three";

export const featureMarkers = [
	{
		icon: "featureHealthy",
		iconHover: "featureHealthyHover",
		iconSize: { width: 12, height: 12 },
		location: { x: 97.097, y: 0, z: 2.441 },
		height: 3,
		data: "FEATURE_HEALTHY",
	},
	{
		icon: "featureQuality",
		iconHover: "featureQualityHover",
		iconSize: { width: 12, height: 12 },
		location: { x: 129.474, y: 0, z: 44.273 },
		height: 8,
		data: "FEATURE_QUALITY",
	},
	{
		icon: "featureResponsible",
		iconHover: "featureResponsibleHover",
		iconSize: { width: 12, height: 12 },
		location: { x: 102.712, y: 0, z: 94.298 },
		height: 5,
		data: "FEATURE_RESPONSIBLE",
	},
	{
		icon: "featureConnected",
		iconHover: "featureConnectedHover",
		iconSize: { width: 12, height: 12 },
		location: { x: 46.382, y: 0, z: 74.239 },
		height: 3,
		data: "FEATURE_CONNECTED",
	},
	{
		icon: "featureSmart",
		iconHover: "featureSmartHover",
		iconSize: { width: 12, height: 12 },
		location: { x: 26.294, y: 0, z: 33.466 },
		height: 3,
		data: "FEATURE_SMART",
	},
];
