import React, { Component } from "react";
import { G } from "../globals";
import U from "../utils";
import Loop from "../viz/Loop";

class Compass extends Component {
	constructor() {
		super();

		this.SetRotation = this.SetRotation.bind(this);
		this.angle = 0;
	}

	componentDidMount() {
		this.loop = new Loop(this.SetRotation).start();
	}

	SetRotation() {
		if (G.cam && G.cam.camera.getAzimuthalAngle && this.arrow) {
			this.angle = U.MapRange(G.cam.camera.getAzimuthalAngle(), -Math.PI, Math.PI, -180, 180);
			// this.arrow.setAttribute(
			// 	"transform",
			// 	`translate(10, 13) rotate(${this.angle}) translate(-10.000000, -13.000000)`
			// );
			this.arrow.style.transform = `rotate(${this.angle}deg)`;
			// console.log(this.angle);
		}
	}

	render() {
		const willShow =
			this.props.currentState &&
			this.props.currentState.options &&
			this.props.currentState.options.showCompass
				? true
				: false;
		return (
			<div
				ref={(ref) => (this.arrow = ref)}
				className={`compass ${willShow ? "" : "hidden"}`}
			></div>
		);
	}
}

export default Compass;