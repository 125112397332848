import React, { Component } from "react";
import { G } from "../globals";

class Contact extends Component {
  constructor() {
    super();

    this.introAnim = this.introAnim.bind(this);
    this.outroAnim = this.outroAnim.bind(this);
  }

  componentDidMount() {
    this.introAnim();
    // document.addEventListener("camDone", this.introAnim);
    document.addEventListener("stateWillChange", this.outroAnim);
  }

  componentWillUnmount() {
    document.removeEventListener("camDone", this.introAnim);
    document.removeEventListener("stateWillChange", this.outroAnim);
  }

  introAnim(e) {
    setTimeout(() => {
      this.slideBg.classList.remove("fade");
      setTimeout(() => {
        this.contactLeft.classList.remove("contact-intro");
        setTimeout(() => {
          this.contactRight.classList.remove("contact-intro");
        }, 400);
      }, 400);
    }, 200);
  }

  outroAnim(e) {
    if (e.detail.newState && e.detail.newState.name === "CONTACT") return;
    this.contactRight.classList.add("contact-outro");
    this.contactLeft.classList.add("contact-outro");
    setTimeout(() => {
      this.slideBg.classList.add("fade");
    }, 400);
  }

  render() {
    return (
      <div className="slide full-width-slide clickable">
        <div
          ref={(ref) => (this.slideBg = ref)}
          className="slide-bg fade"
        ></div>
        <div className="slide-fullwidth-content contact-content">
          <div
            ref={(ref) => (this.contactLeft = ref)}
            className="contact-left-col contact-intro"
          >
            <h1 className="contact-header">
              <span className="red">
                fifty
                <br />
              </span>
              eastbourne terrace,
              <br />
              paddington,
              <br />
              w2 6lg
            </h1>
            <div className="contact-row">
              <div className="contact-logo cbre-logo"></div>
              <div className="contact-name">
                Adam Cosgrove
                <br />
                020 7182 2447
                <br />
                07500 872 851
                <br />
                <a
                  href="mailto:adam.cosgrove@cbre.com"
                  className="contact-link"
                >
                  adam.cosgrove@cbre.com
                </a>
              </div>
              <div className="contact-name">
                Joe Gibbon
                <br />
                020 3257 6173
                <br />
                07585 987 546
                <br />
                <a href="mailto:joe.gibbon@cbre.com" className="contact-link">
                  joe.gibbon@cbre.com
                </a>
              </div>
            </div>
            <div className="contact-row">
              <div className="contact-logo colliers-logo"></div>
              <div className="contact-name">
                Alex Kemp
                <br />
                020 7487 1713
                <br />
                07881 610 962
                <br />
                <a
                  href="mailto:alex.kemp@colliers.com"
                  className="contact-link"
                >
                  alex.kemp@colliers.com
                </a>
              </div>

              <div className="contact-name">
                Mark Radford
                <br />
                020 7344 6972
                <br />
                07730 318 148
                <br />
                <a
                  href="mailto:mark.radford@colliers.com"
                  className="contact-link"
                >
                  mark.radford@colliers.com
                </a>
              </div>
            </div>
            <div className="contact-misrep">
              The owner of this property does not make or give, and neither
              Colliers International nor CBRE or their respective employees have
              authority to make or give, any representation or warranty whatever
              in relation to this property, or its plant, services or electrical
              equipment. The particulars are believed to be correct and, where
              practical, reasonable steps have been taken to ensure their
              accuracy, but this cannot be guaranteed and the particulars are
              expressly excluded through any contract. Colliers International,
              CBRE, and their respective employees accept no responsibility for
              information supplied to them by the owner or any third party.
              November 2021.
              <br />
            </div>
          </div>
          <div
            ref={(ref) => (this.contactRight = ref)}
            className="contact-right-col contact-intro"
          >
            <div className="contact-logo-group">
              <div className="contact-dev-line">Development by</div>
              <a
                href="https://www.invesco.com/corporate/en/home.html"
                target="_blank"
                className="invesco-contact-logo block-display"
              ></a>
              <a
                href="https://yardnine.com"
                target="_blank"
                className="yardnine-logo block-display"
              ></a>
            </div>
            <div
              className="text-link"
              onClick={() => G.showOverlay("showDev", true)}
            >
              view development team
            </div>
            <div className="downloads-block">
              <div className="copy">Downloads</div>

              <ul className="downloads-list w-list-unstyled">
                <li className="download-item">
                  <a
                    className="dl-link"
                    href="./dl/FiftyPaddington_Completion_Pack.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Completion Pack <br />
                    <span className="download-size">(PDF, 509kb)</span>
                  </a>
                </li>
                <li className="download-item">
                  <a
                    className="dl-link"
                    href="./dl/FiftyPaddington_Photos.zip"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Image Library <br />
                    <span className="download-size">(ZIP, 66.8mb)</span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Contact;
