import React, { Component } from "react";
import healthyIllust from "../images/featureHealthy_illust.jpg";

class FeatureHealthy extends Component {
	constructor() {
		super();

		this.introAnim = this.introAnim.bind(this);
		this.outroAnim = this.outroAnim.bind(this);
	}

	componentDidMount() {
		document.addEventListener("camDone", this.introAnim);
		document.addEventListener("stateWillChange", this.outroAnim);
	}

	componentWillUnmount() {
		document.removeEventListener("camDone", this.introAnim);
		document.removeEventListener("stateWillChange", this.outroAnim);
	}

	introAnim(e) {
		if (!e.detail.state || e.detail.state.name !== "FEATURE_HEALTHY") return;

		setTimeout(() => {
			const slideDiv = document.querySelector(".slide");
			this.fadeDivs = [...slideDiv.querySelectorAll(".fade-init")];
			this.heroImg.classList.add("swipe-reveal");
			setTimeout(() => {
				this.featureDiv.classList.add("swipe-reveal");
				this.fadeDivs.map((f) => f.classList.remove("fade"));
			}, 400);
		}, 100);
	}

	outroAnim(e) {
		if (e.detail.newState && e.detail.newState.name === "FEATURE_HEALTHY") return;
		const swipeOuts = [...document.querySelectorAll(".swipe-init")];
		const fadeOuts = [...document.querySelectorAll(".fade-init")];
		swipeOuts.map((s) => {
			s.classList.add("swipe-out");
		});
		fadeOuts.map((f) => {
			f.classList.add("fade");
		});
	}

	render() {
		return (
			<div className="slide feature-slide clickable">
				<div className="slide-close-container">
					<div
						className="slide-close"
						onClick={() => {
							this.props.GoToState("OVERVIEW");
						}}
					></div>
				</div>
				<div className="feature-slide-wrapper">
					<div
						className="feature-slide-container swipe-init"
						ref={(ref) => (this.featureDiv = ref)}
					>
						<div className="enlarge-button feature-button"></div>
						<div
							className="feature-img-block"
							style={{ backgroundImage: `url(${healthyIllust})` }}
						></div>
						<ul className="feature-list w-list-unstyled">
							<li className="feature-item-title">
								<div className="feature-title">Well Certification – Gold</div>
								<div className="feature-copy">
									Leading certification for advancing health and wellbeing in
									buidings
								</div>
							</li>

							<li className="feature-item-title">
								<div className="feature-title">
									Fitness and gym use within the building
								</div>
								<div className="feature-copy">
									An external gym operator located within the building
								</div>
							</li>

							<li className="feature-item-title">
								<div className="feature-title">Plenty of natural light</div>
								<div className="feature-copy">
									Due to the building's prominent corner location and large
									windows, including floor to ceiling glazing and windows on all
									elevations
								</div>
							</li>

							<li className="feature-item-title">
								<div className="feature-title">Green Spaces</div>
								<div className="feature-copy">
									Moments from Hyde Park and many other green spaces
								</div>
							</li>

							<li className="feature-item-title">
								<div className="feature-title">
									Excellent end of trip facilities
								</div>
								<div className="feature-copy">
									Separate male and female showers
									<br />
									Electric charge points
									<br />
									Bike spaces
									<br />
									Drying room
								</div>
							</li>
						</ul>
						<div className="feature-vert-title">
							<div className="title-vert-rotate">
								<h2 className="h2 feature-vert">Work happy</h2>
							</div>
						</div>
					</div>
				</div>
				<div className="feature-slide-main">
					<div
						ref={(ref) => (this.heroImg = ref)}
						style={{
							backgroundImage: `url(./images/gallery/sh3.jpg)`,
							backgroundSize: "cover",
						}}
						className="feature-slide-image swipe-init"
					></div>
				</div>
			</div>
		);
	}
}

export default FeatureHealthy;
