class Loop {
	constructor(fn) {
		//new loop obj
		this.funcToFire = {
			id: funcs.length,
			f: fn
		};
	}

	start() {
		this.funcToFire.id = Date.now();
		funcs.push(this.funcToFire);
		return this;
	}

	stop() {
		funcs = [ ...funcs ].filter((f) => f.id !== this.funcToFire.id);
	}

	static GetActiveLoops() {
		return funcs;
	}
}

export default Loop;

//array of functions
let funcs = [];

function animate() {
	requestAnimationFrame(animate);
	if (funcs.length > 0) {
		for (let i = 0; i < funcs.length; i++) {
			if (funcs[i] && funcs[i].f) funcs[i].f();
		}
	}
}

animate();
