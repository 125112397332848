import React, { Component } from "react";
import { G } from "../globals";

class OverlaySpec extends Component {
	render() {
		return (
			<div className="overlay clickable">
				<div className="overlay-content">
					<div className="slide-close-container">
						<div
							className="slide-close"
							onClick={() => G.showOverlay("showSpec", false)}
						></div>
					</div>
					<div className="overlay-title-block">
						<div className="overlay-title">Specification</div>
			
					</div>
					<div className="specification-content">
						<ul className="specification-list w-list-unstyled">
							<li className="specification-item spec-elizabeth">
								Main entrance opposite the Elizabeth Line concourse for Paddington
								Station{" "}
							</li>
							<li className="specification-item spec-cupola">
								Striking corner cupolas’ incorporating tree planting{" "}
							</li>
							<li className="specification-item spec-planting">
								Private roof terraces to levels L3, 5 &amp; 6 with perimeter
								planting
							</li>
							<li className="specification-item spec-meeting">
								Communal, west facing 6th floor roof terrace and adjacent internal
								meeting room{" "}
							</li>
							<li className="specification-item spec-cafe">
								Integrated café adjacent to reception
							</li>
							<li className="specification-item spec-density">
								Occupational density @ 1:8 sqm{" "}
							</li>
							<li className="specification-item spec-frame">
								Steel frame with hollow rib decking to facilitate vertical
								connectivity
							</li>
							<li className="specification-item spec-breeam">
								BREEAM Excellent 2018
							</li>
							<li className="specification-item spec-wired">Wired Scored Platinum</li>
							<li className="specification-item spec-well">WELL Accredited </li>
							<li className="specification-item spec-terrace">
								3,700 sq ft of roof terraces
							</li>
							<li className="specification-item spec-cycle">143 Cycle spaces </li>
							<li className="specification-item spec-lockers">130 Lockers </li>
							<li className="specification-item">13 Showers  </li>
						</ul>
					</div>
				</div>
				<div className="overlay-bg blue-bg"></div>
			</div>
		);
	}
}

export default OverlaySpec;
