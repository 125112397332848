import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "./css/normalize.css";
import "./css/components.css";
import "./css/fifty-v2.css";
import "./css/style.css";

import { CONFIG } from "./config";

//DETECT DEBUG MODE
if (window.location.hash === "#debug") {
	CONFIG.d_enableDebug = true;
	CONFIG.d_enableDebugUI = true;
	CONFIG.d_enableDebugVar = true;
	CONFIG.d_enableUI = false;
} else {
	CONFIG.d_enableDebug = false;
	CONFIG.d_enableDebugUI = false;
	CONFIG.d_enableDebugVar = false;
	CONFIG.d_enableUI = true;
}

// ReactDOM.render(
// 	<React.StrictMode>
// 		<Entry />
// 	</React.StrictMode>,
// 	document.getElementById("root")
// );

ReactDOM.render(<App />, document.getElementById("root"));
