import React, { Component } from "react";
import { G } from "../globals";

class OverlayOccupier extends Component {
	constructor() {
		super();

		this.introAnim = this.introAnim.bind(this);
	}

	componentDidMount() {
		this.introAnim();
	}

	introAnim(e) {
		setTimeout(() => {
			const overlayDiv = document.querySelector(".overlay");
			this.fadeDivs = [...overlayDiv.querySelectorAll(".fade-init")];
			this.overlayBg.classList.remove("fade");
			setTimeout(() => {
				this.featureDiv.classList.add("swipe-reveal");
				this.fadeDivs.map((f) => f.classList.remove("fade"));
			}, 400);
		}, 100);
	}

	render() {
		return (
			<div className="overlay clickable">
				<div className="overlay-content">
					<div className="slide-close-container">
						<div
							className="slide-close"
							onClick={() => G.showOverlay("showOccupiers", false)}
						></div>
					</div>
					<div
						ref={(ref) => (this.featureDiv = ref)}
						className="overlay-main-container swipe-init"
					>
						<div className="development-content fade fade-init">
							<div className="development-col-left">
								<h2 className="overlay-title">Your neighbours</h2>
								<ul className="occupiers-list w-list-unstyled">
									<li className="occupier-item occ-adameve"></li>

									<li className="occupier-item logo-future"></li>
									<li className="occupier-item logo-hellman"></li>
									<li className="occupier-item logo-iqvia"></li>
									<li className="occupier-item logo-kingfisher"></li>
									<li className="occupier-item logo-mars"></li>
									<li className="occupier-item logo-ms"></li>
									<li className="occupier-item logo-microsoft"></li>
									<li className="occupier-item logo-mitsu"></li>
									<li className="occupier-item logo-payment"></li>
									<li className="occupier-item logo-premier"></li>
									<li className="occupier-item logo-sony"></li>
									<li className="occupier-item logo-takeda"></li>
									<li className="occupier-item logo-vertex"></li>
									<li className="occupier-item logo-visa"></li>
									<li className="occupier-item logo-vodafone"></li>
								</ul>
							</div>
						</div>
					</div>
				</div>
				<div
					ref={(ref) => (this.overlayBg = ref)}
					className="overlay-bg blue-bg fade-init fade "
				></div>
			</div>
		);
	}
}

export default OverlayOccupier;
