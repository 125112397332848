import React, { Component } from "react";
import { G } from "../globals";

class OverlayGallery extends Component {
	constructor(props) {
		super();

		this.gallery = {
			images: props.content,
			isSingle: !Array.isArray(props.content) || props.content.length <= 1,
			galleryImageContainer: null,
			thumbWrappper: null,
			currentSlide: 0,
		};
	}

	componentDidMount() {
		this.gallery.galleryImageContainer = this.galleryImg;
		this.gallery.thumbWrappper = this.thumbDiv;
		this.jumpToSlide(0);
	}

	jumpToSlide(index) {
		// this.galleryImg.classList.add("fade-out");

		// setTimeout(() => {
			this.gallery.galleryImageContainer.style.backgroundImage = `url(${this.gallery.images[index]})`;
			if (this.props.isContain) this.gallery.galleryImageContainer.style.backgroundSize = "contain";
			setTimeout(() => {
				this.galleryImg.classList.remove("fade-out");
			}, 100);

			const thumbs = Array.from(this.gallery.thumbWrappper.children);
			thumbs.map((thumb) => thumb.classList.remove("gallery-thumb-active"));
			thumbs[index].classList.add("gallery-thumb-active");
		// }, 400);
	}

	navigateSlides(_dir) {
		let dir = 0;

		if (_dir === "next") {
			dir = +1;
		} else if (_dir === "prev") {
			dir = -1;
		}
		this.gallery.currentSlide += dir;

		if (this.gallery.currentSlide > this.gallery.images.length - 1) {
			this.gallery.currentSlide = 0;
		} else if (this.gallery.currentSlide < 0) {
			this.gallery.currentSlide = this.gallery.images.length - 1;
		}

		this.jumpToSlide(this.gallery.currentSlide);
	}

	render() {
		return (
			<div className="overlay gallery-overlay clickable">
				<div className="gallery-viewer">
					<div ref={(ref) => (this.galleryImg = ref)} className="gallery-image"></div>
					<div className={`gallery-controls ${this.gallery.isSingle ? "hidden" : ""}`}>
						<ul
							ref={(ref) => (this.thumbDiv = ref)}
							className="gallery-thumb-list w-list-unstyled"
						>
							{this.gallery.images.map((img, i) => {
								return (
									<div
										key={i}
										className="gallery-thumb"
										onClick={() => this.jumpToSlide(i)}
									></div>
								);
							})}
						</ul>
						<div
							className="gallery-control-button gallery-prev"
							onClick={() => this.navigateSlides("prev")}
						></div>
						<div
							className="gallery-control-button gallery-next"
							onClick={() => this.navigateSlides("next")}
						></div>
					</div>
				</div>
				<div className="slide-close-container">
					<div
						className="slide-close"
						onClick={() => G.showOverlay("showGallery", false)}
					></div>
				</div>
				<div className="overlay-bg blue-bg"></div>
			</div>
		);
	}
}

export default OverlayGallery;
