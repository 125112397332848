import React, { Component } from "react";
import { G } from "../globals";

class GalleryThumb extends Component {
	constructor(props) {
		super();

		this.currentSlide = 0;
		this.thumbContent = props.thumbContent && Array.isArray(props.thumbContent) && props.thumbContent.length > 0 ? props.thumbContent : props.content;
		this.isSingle = !Array.isArray(this.thumbContent) || this.thumbContent.length === 1;
	}

	componentDidMount() {
		this.jumpToSlide(0);
		setTimeout(() => { this.animate(); }, 10000);
		
	}

	animate() {
		if (this.isSingle) return;
		if (!this.galleryImg) return;
		this.navigateSlides("next");
		setTimeout(() => {
			if (!this.galleryImg) return;
			this.animate();
		}, 5000);
	}

	jumpToSlide(index) {
		// this.galleryImg.classList.add("fade-out");

		// setTimeout(() => {
			if (!this.galleryImg) return;
			this.galleryImg.style.backgroundImage = `url(${this.thumbContent[index]})`;
			// this.galleryImg.classList.remove("fade-out");
		// }, 700);
	}

	navigateSlides(_dir) {
		let dir = 0;

		if (_dir === "next") {
			dir = +1;
		} else if (_dir === "prev") {
			dir = -1;
		}
		this.currentSlide += dir;

		if (this.currentSlide > this.thumbContent.length - 1) {
			this.currentSlide = 0;
		} else if (this.currentSlide < 0) {
			this.currentSlide = this.thumbContent.length - 1;
		}

		this.jumpToSlide(this.currentSlide);
	}

	render() {
		return (
			<div
				parallax-rate={6}
				className="thumb-gallery img-placeholder swipe-init"
				onClick={() => G.showOverlay("showGallery", this.props.content)}
			>
				<div
					ref={(ref) => (this.galleryImg = ref)}
					style={{ backgroundImage: `url(${this.props.content[0]})` }}
					onClick={() => G.showOverlay("showGallery", this.props.content)}
					className="thumb-gallery-img img-placeholder fade-init"
				></div>
				<div className="enlarge-button fade-init fade"></div>
			</div>
		);
	}
}

export default GalleryThumb;
