import React, { Component } from "react";

class Intro extends Component {
  constructor() {
    super();

    this.introAnim = this.introAnim.bind(this);
    this.outroAnim = this.outroAnim.bind(this);
  }

  componentDidMount() {
    document.addEventListener("loadDone", this.introAnim);
    document.addEventListener("stateWillChange", this.outroAnim);
  }

  componentWillUnmount() {
    document.removeEventListener("loadDone", this.introAnim);
    document.removeEventListener("stateWillChange", this.outroAnim);
  }

  introAnim() {
    const headerLines = [
      ...this.introHeaderList.querySelectorAll(".intro-header-text"),
    ];

    setTimeout(() => {
      if (this.props.currentState.name !== "INTRO") return;
      if (!this.introButton) return;

      this.videoPreroll.play();

      headerLines.map((h, i) => {
        setTimeout(() => {
          h.classList.remove("intro-ready");
        }, i * 300);
      });

      setTimeout(() => {
        this.introButton.classList.remove("intro-ready");
      }, 2000);
    }, 1600);
  }

  outroAnim(e) {
    console.log(e);
    if (e.detail.newState.name !== "OVERVIEW") return;
    this.slideDiv.classList.add("outro");
  }

  render() {
    return (
      <div
        ref={(ref) => {
          this.slideDiv = ref;
        }}
        className="slide intro-slide clickable"
      >
        <div
          ref={(ref) => (this.slideBg = ref)}
          className="video-intro-slide-bg"
        >
          <video
            className="intro-video-preroll"
            ref={(ref) => (this.videoPreroll = ref)}
            loop
            playsInline
            muted
          >
            <source src="./video/fifty_intro_preroll.mp4" type="video/mp4" />
          </video>
          <div className="intro-slidebg-gradient"></div>
        </div>
        <div className="intro-slide-content">
          <div className="intro-header-wrapper">
            <ul
              ref={(ref) => (this.introHeaderList = ref)}
              className="intro-header-list parallax w-list-unstyled"
            >
              <li className="intro-header-item">
                <div className="intro-header-text intro-ready">
                  4,000 – 39,008 sq ft,
                </div>
              </li>
              <li className="intro-header-item">
                <div className="intro-header-text intro-ready">
                  corner building,
                </div>
              </li>
              <li className="intro-header-item">
                <div className="intro-header-text intro-ready">
                  perfectly positioned,
                </div>
              </li>
              <li className="intro-header-item">
                <div className="intro-header-text available-text intro-ready">
                  available now,
                </div>
              </li>
              <li className="intro-header-item">
                <div className="intro-header-text intro-ready">
                  <span className="fifty-text-logo">fifty</span>
                </div>
              </li>
            </ul>
          </div>
          <div
            ref={(ref) => (this.introButton = ref)}
            className="intro-button parallax intro-ready"
            onClick={() => this.props.GoToState("OVERVIEW")}
          >
            start exploring
          </div>
        </div>
      </div>
    );
  }
}

export default Intro;
