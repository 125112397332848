import * as THREE from "three";
import { TextGeometry } from "three/examples/jsm/geometries/TextGeometry";
import U from "../../utils";
import { CONFIG } from "../../config";
import Entity from "../Entity";

class StreetMarker extends Entity {
	constructor(params) {
		super();

		this.SetOrientation = this.SetOrientation.bind(this);

		const textGeo = new TextGeometry(params.markerData.text, {
			font: params.font,
			size: 2,
			height: 0.01,
			curveSegments: CONFIG.r_textCurveSegments,
		});

		const mat = new THREE.MeshBasicMaterial({
			color: new THREE.Color("#F0524B"),
			toneMapped: false
		});

		this.params = params;
		this.markerMesh = new THREE.Mesh(textGeo, mat);
		this.markerMesh.position.set(0, 0, 0);
		this.marker = new THREE.Group();
		this.marker.add(this.markerMesh);

		document.addEventListener("compassChange", this.SetOrientation);

		//letting things load
		setTimeout(() => {
			this.markerMesh.geometry.computeBoundingBox();
			const centerPos = {
				x:
					(this.markerMesh.geometry.boundingBox.max.x -
						this.markerMesh.geometry.boundingBox.min.x) /
					2,
				z:
					(this.markerMesh.geometry.boundingBox.max.z -
						this.markerMesh.geometry.boundingBox.min.z) /
					2,
			};
			this.marker.position.set(
				params.markerData.location.x,
				0,
				params.markerData.location.z
			);
			this.marker.rotation.set(
				-Math.PI / 2,
				0,
				U.convertDegreesToRadians(params.markerData.rotation - 8)
			);
			this.markerMesh.position.set(-centerPos.x, this.markerMesh.position.y, -centerPos.z);
		}, 300);
	}

	SetOrientation(e) {
		if (
			e.detail.degree > this.params.markerData.rotation + 90 ||
			e.detail.degree < this.params.markerData.rotation - 90
		) {
			this.marker.rotation.set(
				-Math.PI / 2,
				0,
				U.convertDegreesToRadians(this.params.markerData.rotation + 180)
			);
		} else {
			this.marker.rotation.set(
				-Math.PI / 2,
				0,
				U.convertDegreesToRadians(this.params.markerData.rotation)
			);
		}

		// if (e.detail.degree > this.params.markerData.rotation + 180) {
		// 	this.marker.rotation.set(-Math.PI / 2, 0, U.convertDegreesToRadians(this.params.markerData.rotation + 180));
		// } else if (e.detail.degree < this.params.markerData.rotation - 180) {
		// 	this.marker.rotation.set(-Math.PI / 2, 0, U.convertDegreesToRadians(this.params.markerData.rotation - 180));
		// } else {
		// 	this.marker.rotation.set(-Math.PI / 2, 0, U.convertDegreesToRadians(this.params.markerData.rotation));
		// }
		// console.log(e);
	}
}

export default StreetMarker;
