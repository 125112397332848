import React, { Component } from "react";
import connectedImg from "../images/featureConnected_hero.jpg";
import connectedIllust from "../images/featureConnected_illust.jpg";

class FeatureConnected extends Component {
	constructor() {
		super();

		this.introAnim = this.introAnim.bind(this);
		this.outroAnim = this.outroAnim.bind(this);
	}

	componentDidMount() {
		document.addEventListener("camDone", this.introAnim);
		document.addEventListener("stateWillChange", this.outroAnim);
	}

	componentWillUnmount() {
		document.removeEventListener("camDone", this.introAnim);
		document.removeEventListener("stateWillChange", this.outroAnim);
	}

	introAnim(e) {
		if (!e.detail.state || e.detail.state.name !== "FEATURE_CONNECTED") return;

		setTimeout(() => {
			const slideDiv = document.querySelector(".slide");
			this.fadeDivs = [...slideDiv.querySelectorAll(".fade-init")];
			this.heroImg.classList.add("swipe-reveal");
			setTimeout(() => {
				this.featureDiv.classList.add("swipe-reveal");
				this.fadeDivs.map((f) => f.classList.remove("fade"));
			}, 400);
		}, 100);
	}

	outroAnim(e) {
		if (e.detail.newState && e.detail.newState.name === "FEATURE_CONNECTED") return;
		const swipeOuts = [...document.querySelectorAll(".swipe-init")];
		const fadeOuts = [...document.querySelectorAll(".fade-init")];
		swipeOuts.map((s) => {
			s.classList.add("swipe-out");
		});
		fadeOuts.map((f) => {
			f.classList.add("fade");
		});
	}

	render() {
		return (
			<div className="slide feature-slide clickable">
				<div className="slide-close-container">
					<div
						className="slide-close"
						onClick={() => {
							this.props.GoToState("OVERVIEW");
						}}
					></div>
				</div>
				<div className="feature-slide-wrapper">
					<div
						className="feature-slide-container swipe-init"
						ref={(ref) => (this.featureDiv = ref)}
					>
						<div className="enlarge-button feature-button"></div>
						<div
							className="feature-img-block"
							style={{ backgroundImage: `url(${connectedIllust})` }}
						></div>
						<ul className="feature-list w-list-unstyled">
							<li className="feature-item-title">
								<div className="feature-title">
									Entrance to Elizabeth Line right at your doorstep
								</div>
							</li>
							<li className="feature-item-title">
								<div className="feature-title">
									Access to 6 tube lines
								</div>
								<div className="feature-copy">
									Bakerloo / Circle / District Line / Hammersmith & City / Central
									/ Elizabeth Line
								</div>
							</li>
							<li className="feature-item-title">
								<div className="feature-title">
									20 minutes to Heathrow Airport by train, providing international links
								</div>
							</li>
							<li className="feature-item-title">
								<div className="feature-title">
									Access to the West, South West and wider UK via Paddington
									National Rail
								</div>
							</li>
							<li className="feature-item-title">
								<div className="feature-title">
									10,000 sq ft of restaurant and leisure space within the building
								</div>
							</li>
							<li className="feature-item-title">
								<div className="feature-title">
									Diverse mix of local amenities – restaurants, hotels, cafes and
									bars
								</div>
							</li>
						</ul>
						<div className="feature-vert-title">
							<div className="title-vert-rotate">
								<h2 className="h2 feature-vert">Access greater things</h2>
							</div>
						</div>
					</div>
				</div>
				<div className="feature-slide-main">
					<div
						ref={(ref) => (this.heroImg = ref)}
						style={{
							backgroundImage: `url(./images/featConnectedHero.jpg)`,
							backgroundSize: "cover",
						}}
						className="feature-slide-image swipe-init"
					></div>
				</div>
			</div>
		);
	}
}

export default FeatureConnected;
