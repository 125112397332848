import React, { Component } from 'react';
import pannellum from 'pannellum';
import { G } from '../globals';
import U from '../utils';

class OverlayVirtual extends Component {

  constructor() {
		super();

		//import panellum via index.html because it's not a module, then bring it here
    this.pannellum = window.pannellum;
    this.fakePannellum = pannellum;

		this.viewer = null;
	}

	componentDidMount() {
		// this.viewer = this.pannellum.getViewer();
		this.Init();
	}

	componentDidUpdate() {
		
	}

  Init() {

		this.viewer = this.pannellum.viewer('pano', {
			mouseZoom: false,
			keyboardZoom: false,
			showControls: false,
			scenes: {
				floor4th: {
					type: 'multires',
					multiRes: {
						basePath: './images/360/4thfloor',
						path: '/%l/%s%y_%x',
						fallBackPath: '/fallback/%s',
						extension: 'jpg',
						tileResolution: 512,
						maxLevel: 3,
						cubeResolution: 1904
					},
					hotSpots: [
						{
							type: 'custom',
							pitch: -20,
							yaw: 0,
							sceneId: 'floor4thCatA',
							text: 'Switch to Cat A',
							cssClass: 'custom-hotspot'
						}
					],
					hfov: 100,
					pitch: 0,
					yaw: 0
				},
				rooftop: {
					type: 'multires',
					sceneId: 'rooftop',
					multiRes: {
						basePath: './images/360/rooftop',
						path: '/%l/%s%y_%x',
						fallBackPath: '/fallback/%s',
						extension: 'jpg',
						tileResolution: 512,
						maxLevel: 3,
						cubeResolution: 1904
					},
					hfov: 100,
					pitch: 9,
					yaw: 325,
					minPitch: 1,
					maxPitch: 9
				},

				floor4thCatA: {
					sceneId: 'floor4thCatA',
					type: 'multires',
					multiRes: {
						basePath: './images/360/4thfloorCatA',
						path: '/%l/%s%y_%x',
						fallBackPath: '/fallback/%s',
						extension: 'jpg',
						tileResolution: 512,
						maxLevel: 3,
						cubeResolution: 1904
					},
					hotSpots: [
						{
							type: 'custom',
							pitch: -20,
							yaw: 0,
							sceneId: 'floor4th',
							text: 'Switch to fit out',
							cssClass: 'custom-hotspot'
						}
					],
					hfov: 100,
					pitch: 0,
					yaw: 0
				},
				floor5th: {
					type: 'multires',
					sceneId: 'floor5th',
					multiRes: {
						basePath: './images/360/5thfloor',
						path: '/%l/%s%y_%x',
						fallBackPath: '/fallback/%s',
						extension: 'jpg',
						tileResolution: 512,
						maxLevel: 3,
						cubeResolution: 1904
					},
					hfov: 100,
					pitch: 0,
					yaw: 0
				}
			}
    });
   
    if (this.props.content && typeof this.props.content === 'string') {
			this.viewer.loadScene(this.props.content);
		}

    
	}

  render() {
    return <div className="overlay gallery-overlay clickable">
          <div className="virtual-viewer">
            <div id="pano" className="gallery-image"></div>
          </div>
          <div className="slide-close-container">
            <div className="slide-close" onClick={() => G.showOverlay('showVirtual', false)}></div>
          </div>
          <div className="overlay-bg blue-bg"></div>
        </div>;
  }
}

export default OverlayVirtual;