import React, { Component } from "react";
import { G } from "../globals";
import U from "../utils";
import Instruction from "./instruction";

class Instructions extends Component {
	constructor() {
		super();

		document.addEventListener("camDone", (e) => {
			this.forceUpdate();
			// setTimeout(() => {
			// 	this.IntroAnim();
			// }, 100);
		});
	}

	ShowInstructions() {
		let result = { orbit: false, pan: false, zoom: false };
		if (
			this.props.currentState !== undefined &&
			this.props.currentState &&
			this.props.currentState.camData !== undefined &&
			this.props.currentState.camData.controls !== undefined
		) {
			this.props.currentState.camData.controls.map((c) => {
				if (c === "all") result = { orbit: true, pan: true, zoom: true };
				else if (c === "orbit" || c === "rotate") result.orbit = true;
				else if (c === "pan") result.pan = true;
				else if (c === "zoom") result.zoom = true;
				return null;
			});
		}
		return result;
	}

	GetInstruction(type) {
		const button = G.cam && G.cam.controls.controlPriority.PRIMARY === type ? "LEFT" : "RIGHT";
		switch (type) {
			case "orbit":
			case "rotate":
				return U.GetResponsiveMode().isTouch
					? {
							icon: "orbit-icon",
            text: [`Use two fingers to `, <span key={1} className="instruction-highlight">rotate camera</span>]
            }
					: {
							icon: "orbit-icon",
							text: [`${
								button === "RIGHT" ? "Right" : "Left"
							} click and drag to `,  <span key={2} className="instruction-highlight">rotate camera</span>],
					  };
			case "pan":
				return U.GetResponsiveMode().isTouch
					? {
							icon: "pan-icon",
							text: [`Touch and drag to `, <span key={3} className="instruction-highlight">look around</span>],
					  }
					: {
							icon: "pan-icon",
							text: [`${
								button === "RIGHT" ? "Right" : "Left"
							} click and drag to `, <span key={4} className="instruction-highlight">pan around</span>, ` the area`],
					  };
			case "zoom":
				return U.GetResponsiveMode().isTouch
					? {
							icon: "zoom-icon",
							text: [`Pinch to `, <span key={5} className="instruction-highlight">zoom in and out</span>],
					  }
					: {
							icon: "zoom-icon",
							text: [`Scroll to `, <span key={6} className="instruction-highlight">zoom in and out</span>],
					  };
			default:
				break;
		}
	}

	render() {
		const s = this.ShowInstructions();
		return (
			<ul
				className={`instructions-wrapper clickable w-list-unstyled ${
					this.props.currentState &&
					this.props.currentState.options &&
					this.props.currentState.options.showInstructions
						? ""
						: "hidden"
				}`}
			>
				<Instruction show={s.orbit} icon={this.GetInstruction("orbit").icon}>
					{this.GetInstruction("orbit").text}
				</Instruction>
				<Instruction show={s.pan} icon={this.GetInstruction("pan").icon}>
					{this.GetInstruction("pan").text}
				</Instruction>
				<Instruction show={s.zoom} icon={this.GetInstruction("zoom").icon}>
					{this.GetInstruction("zoom").text}
				</Instruction>
				<li onClick={() => G.cam.Reset()} className="instruction-reset-button clickable">
					Reset
				</li>
			</ul>
		);
	}
}

export default Instructions;