import * as THREE from "three";
import { TextGeometry } from "three/examples/jsm/geometries/TextGeometry";
import { CONFIG } from "../../config";
import { G } from "../../globals";
import Entity from "../Entity";
import Loop from "../Loop";
import U from "../../utils";

class HighlightMarker extends Entity {
	constructor(params) {
		super();

		/* 
            This marker is designed for special areas, transport stations, and other features that need to be highlighted
            Optionally has a pinpoint line.
            Optionally can have an icon.
            Non-interactive.
            Label and icon has look-at, pinpoint line doesn't
            Expects:
            { markerData: object, font: loader asset }
        */

		this.marker = new THREE.Group();
		this.markerVLookGroup = new THREE.Group();
		this.markerLookGroup = new THREE.Group();
		this.markerLabelGroup = new THREE.Group();
		this.markerStaticGroup = new THREE.Group();

		this.params = params;

		this.scaleRate = params.scaleRate ? params.scaleRate : 150;

		if (params.markerData.text && params.markerData.text.length > 0) {
			const textGeo = new TextGeometry(params.markerData.text, {
				font: params.font,
				size: 1.2,
				height: 0.01,
				curveSegments: CONFIG.r_textCurveSegments,
			});

			this.textMesh = new THREE.Mesh(
				textGeo,
				new THREE.MeshBasicMaterial({
					toneMapped: false,
					color: new THREE.Color(
						params.color ? params.color : "#0A2342"
					),
				})
			);

			this.markerLabelGroup.add(this.textMesh);
		}

		if (params.pinpointLine) {
			const pinPoint = new THREE.Mesh(
				new THREE.CylinderGeometry(0.1, 0.1, 10, 3, 1),
				new THREE.MeshBasicMaterial({
					toneMapped: false,
					color: new THREE.Color("#0A2342"),
				})
			);

			pinPoint.position.set(0, -6.5, 0);

			this.markerStaticGroup.add(pinPoint);
		}

		if (params.icon) {
			this.markerIcon = new THREE.Mesh(
				new THREE.PlaneGeometry(params.iconSize.width, params.iconSize.height),
				new THREE.MeshBasicMaterial({
					color: new THREE.Color(
						params.color ? params.color : "#FFCF40"
					),
					map: params.icon,
					transparent: true,
					toneMapped: false,
				})
			);

			this.markerIcon.material.map.encoding = THREE.sRGBEncoding;
			this.markerIcon.material.map.anisotropy = CONFIG.r_anisotropy;
			this.markerIcon.material.map.magFilter = THREE.LinearFilter;
			this.markerIcon.material.map.minFilter = THREE.LinearMipMapLinearFilter;
			this.markerIcon.material.blending = THREE.CustomBlending;
			this.markerIcon.material.blendSrc = THREE.OneFactor;
			this.markerIcon.material.blendDst = THREE.OneMinusSrcAlphaFactor;

			this.markerIcon.position.set(
				this.textMesh
					? -params.iconSize.width + params.iconOffset.x
					: 0 + params.iconOffset.x,
				params.iconSize.height / 2 + params.iconOffset.y,
				0
			);

			this.markerLabelGroup.add(this.markerIcon);
		}

		this.markerLookGroup.add(this.markerLabelGroup);
		this.markerVLookGroup.add(this.markerStaticGroup);
		this.markerVLookGroup.add(this.markerLabelGroup);
		this.markerLookGroup.add(this.markerVLookGroup);
		this.marker.add(this.markerLookGroup);

		if (params.isClickable) this.enableInteraction(this.markerIcon);

		this.loop = new Loop(() => {
			if (params.scaleWithZoom) this.ScaleWithZoom();
			if (this.marker.visible) this.RotateToFaceCamera();
		}).start();

		setTimeout(() => {
			let centerLabelPos;

			if (params.icon) this.markerIcon.geometry.computeBoundingBox();

			const iconOffset = params.icon
				? this.markerIcon.geometry.boundingBox
				: { max: { x: 0, z: 0 }, min: { x: 0, z: 0 } };

			if (this.textMesh) {
				this.textMesh.geometry.computeBoundingBox();
				centerLabelPos = {
					x:
						(this.textMesh.geometry.boundingBox.max.x -
							this.textMesh.geometry.boundingBox.min.x) /
							2 -
						(iconOffset.max.x - iconOffset.min.x) / 2,
					z:
						(this.textMesh.geometry.boundingBox.max.z -
							this.textMesh.geometry.boundingBox.min.z) /
							2 -
						(iconOffset.max.z - iconOffset.min.z) / 2,
				};

				this.markerLabelGroup.position.set(
					-centerLabelPos.x,
					this.markerLabelGroup.position.y,
					-centerLabelPos.z
				);
			}

			this.markerLabelGroup.scale.set(
				this.markerLabelGroup.scale.x * params.forceScale,
				this.markerLabelGroup.scale.y * params.forceScale,
				this.markerLabelGroup.scale.z * params.forceScale
			);

			this.marker.position.set(
				params.markerData.location.x,
				params.markerData.height ? params.markerData.height : 5,
				params.markerData.location.z
			);
		}, 300);
	}

	onClick() {
		if (
			this.params.isClickable &&
			this.params.fn &&
			this.params.markerData.data &&
			this.isVisible
		) {
			if (!this.isActive) {
				console.log(this.params.markerData.data);
				if (this.params.markerData.data) this.params.fn(this.params.markerData.data);
				else this.params.fn();
				// G.GoToState(this.params.markerData.data);
			}
		}
	}

	onMouseEnter() {
		if (this.params.isClickable && this.isVisible) {
			document.body.style.cursor = "pointer";
			this.markerIcon.material.map = this.params.iconActive;
			// this.planeMesh.material.color = new THREE.Color("#202020").convertGammaToLinear(2.2);
			// this.subPlaneMesh.material.color = new THREE.Color("#ffffff").convertGammaToLinear(2.2);
			// this.subTextMesh.material.color = new THREE.Color("#9B7564").convertGammaToLinear(2.2);
		}
	}

	onMouseLeave() {
		if (this.params.isClickable) {
			document.body.style.cursor = "auto";
			this.markerIcon.material.map = this.params.icon;
		}
		// this.planeMesh.material.color = new THREE.Color(
		// 	this.params.markerData.color ? this.params.markerData.color : "white"
		// ).convertGammaToLinear(2.2);
		// this.subPlaneMesh.material.color = new THREE.Color("#121314").convertGammaToLinear(2.2);
		// this.subTextMesh.material.color = new THREE.Color("#ffffff").convertGammaToLinear(2.2);
	}

	ScaleWithZoom() {
		if (G.cam.camera.zoomLevel > 140)
			this.markerLabelGroup.scale.set(
				(1 * G.cam.camera.zoomLevel) / this.scaleRate,
				(1 * G.cam.camera.zoomLevel) / this.scaleRate,
				(1 * G.cam.camera.zoomLevel) / this.scaleRate
			);
		else this.markerLabelGroup.scale.set(1, 1, 1);
	}

	RotateToFaceCamera() {
		if (G.cam.camera.getPolarAngle && G.cam.camera.getPolarAngle() < 0.25) {
			this.markerLookGroup.rotation.set(-Math.PI / 2, 0, G.cam.camera.getAzimuthalAngle());
			this.markerVLookGroup.rotation.set(0, 0, 0);
		} else if (G.cam.camera.getPolarAngle) {
			this.markerLookGroup.rotation.set(0, G.cam.camera.getAzimuthalAngle(), 0);
			this.markerVLookGroup.rotation.set(
				-U.MapRange(G.cam.camera.getPolarAngle(), 0, Math.PI / 2, Math.PI / 2, 0),
				0,
				0
			); //polar angle = 1.5 (half pi) -  angle should be 0, polar angle = 0 - angle should be 1.5 (or half pi)
			// this.markerLookGroup.lookAt(G.cam.camera.position);
		}
	}
}

export default HighlightMarker;
