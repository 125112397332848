import React, { Component } from "react";
import { CONFIG } from "../config";
import Loader from "./Loader";
import Nav from "./nav";
import U from "../utils";
import Overlays from "../components/overlays";
import Instructions from "./instructions";
import Compass from "./compass";

class UI extends Component {
	constructor() {
		super();

		this.parallaxItems = [];
	}
	// shouldComponentUpdate(nextProps) {
	// 	//Keep same UI if going to a state without UI set or defined as same - allows us to control 3D views without affecting UI elements
	// 	if (!nextProps.currentState.ui || nextProps.currentState.ui === this.props.currentState.ui) return false;
	// 	else return true;
	// }

	componentDidUpdate() {
		this.parallaxItems = [...document.querySelectorAll(".parallax")].map((p) => {
			return {
				div: p,
				rate: p.getAttribute("parallax-rate") ? Number(p.getAttribute("parallax-rate")) : 2,
			};
		});
	}

	componentDidMount() {
		this.initParallax();
	}

	initParallax() {
		this.parallaxItems = [...document.querySelectorAll(".parallax")].map((p) => {
			return {
				div: p,
				rate: p.getAttribute("parallax-rate") ? Number(p.getAttribute("parallax-rate")) : 2,
			};
		});
		console.log(this.parallaxItems);

		window.addEventListener("mousemove", (e) => {
			const x = U.MapRange(e.clientX, 0, window.innerWidth, -1, 1);
			const y = U.MapRange(e.clientY, 0, window.innerHeight, -1, 1);

			this.parallaxItems.map((p) => {
				p.div.style.left = `${-x * p.rate}px`;
				p.div.style.top = `${-y * p.rate}px`;
			});
		});
	}

	render() {
		const CurrentView =
			this.props.currentState && this.props.currentState.ui
				? this.props.currentState.ui
				: this.props.prevState && this.props.prevState.ui
				? this.props.prevState.ui
				: null;

		return (
			<div className="ui">
				{CurrentView ? (
					<CurrentView
						currentState={this.props.currentState}
						GoToState={this.props.GoToState}
						data={this.props.data}
					/>
				) : null}
				<Overlays />
				<Nav currentState={this.props.currentState} GoToState={this.props.GoToState} />
				<Loader />
				<div className="viz-info">
					<Compass currentState={this.props.currentState} />
					<Instructions currentState={this.props.currentState} />
				</div>
				<div className="content-fade-container">
					<div className="content-fade"></div>
					<div className="content-bottom-fade"></div>
					<div className="content-top-fade"></div>
					<div className="content-right-fade"></div>
				</div>
			</div>
		);
	}
}

export default UI;
