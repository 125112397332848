import React, { Component } from "react";
import { G } from "../globals";
import GalleryThumb from "./galleryThumb";

class Building extends Component {
  constructor() {
    super();

    this.introAnim = this.introAnim.bind(this);
    this.outroAnim = this.outroAnim.bind(this);
  }

  componentDidMount() {
    document.addEventListener("camDone", this.introAnim);
    document.addEventListener("stateWillChange", this.outroAnim);
  }

  componentWillUnmount() {
    document.removeEventListener("camDone", this.introAnim);
    document.removeEventListener("stateWillChange", this.outroAnim);
  }

  introAnim(e) {
    if (!e.detail.state || e.detail.state.name !== "BUILDING") return;

    setTimeout(() => {
      const slideDiv = document.querySelector(".slide");
      this.fadeDivs = [...slideDiv.querySelectorAll(".fade-init")];
      this.slidePopout = document.querySelector(".thumb-gallery");
      this.slidePopout.classList.add("swipe-reveal");
      setTimeout(() => {
        this.slideBg.classList.add("swipe-reveal");
        this.fadeDivs.map((f) => f.classList.remove("fade"));
      }, 200);
    }, 100);
  }

  outroAnim(e) {
    if (e.detail.newState && e.detail.newState.name === "BUILDING") return;
    const swipeOuts = [...document.querySelectorAll(".swipe-init")];
    const fadeOuts = [...document.querySelectorAll(".fade-init")];
    swipeOuts.map((s) => {
      s.classList.add("swipe-out");
    });
    fadeOuts.map((f) => {
      f.classList.add("fade");
    });
  }

  render() {
    return (
      <div className="slide ">
        <div className="slide-placer"></div>
        <div className="slide-panel clickable building-panel parallax">
          <div className="panel-padding-container fade-init fade">
            <div className="copy">
              Now complete, fifty provides 25,355 sq ft of high-quality offices
              and 10,000 sq ft of restaurant and leisure space adjacent to
              Paddington Station.
            </div>
            <ul className="slide-horiz-nav nav-fall-in w-list-unstyled">
              <li
                className="slide-horiz-item"
                onClick={() => this.props.GoToState("FOURTH")}
              >
                explore floors
              </li>
              <li
                className="slide-horiz-item"
                onClick={() => G.showOverlay("showSpec")}
              >
                specification
              </li>
            </ul>
          </div>
          <div
            ref={(ref) => (this.slideBg = ref)}
            className="building-panel-bg swipe-init"
          ></div>
          <div className="slide-popout">
            <GalleryThumb
              thumbContent={[
                "./images/gallery/ext1.jpg",
                "./images/gallery/ext2.jpg",
                "./images/gallery/ext3.jpg",
                "./images/gallery/ext4.jpg",
                "./images/gallery/am1.jpg",
                "./images/gallery/am2.jpg",
                "./images/gallery/am3.jpg",
                "./images/gallery/rec3.jpg",
                "./images/gallery/sec1.jpg",
                "./images/gallery/sec2.jpg",
                "./images/gallery/sec3.jpg",
                "./images/gallery/ter1.jpg",
                "./images/gallery/fit1a.jpg",
                "./images/gallery/fit2.jpg",
                "./images/gallery/ter1.jpg",
                "./images/gallery/ter4.jpg",
                "./images/gallery/sh1.jpg",
                "./images/gallery/sh2.jpg",
                "./images/gallery/sh3.jpg",
                "./images/gallery/ext4.jpg",
              ]}
              content={[
                "./images/gallery/ext1.jpg",
                "./images/gallery/ext2.jpg",
                "./images/gallery/ext3.jpg",
                "./images/gallery/ext4.jpg",
                "./images/gallery/am1.jpg",
                "./images/gallery/am2.jpg",
                "./images/gallery/am3.jpg",
                "./images/gallery/rec1.jpg",
                "./images/gallery/rec2.jpg",
                "./images/gallery/rec3.jpg",
                "./images/gallery/sec1.jpg",
                "./images/gallery/sec2.jpg",
                "./images/gallery/sec3.jpg",
                "./images/gallery/fif1.jpg",
                "./images/gallery/fif2.jpg",
                "./images/gallery/ter1.jpg",
                "./images/gallery/fit1a.jpg",
                "./images/gallery/fit2.jpg",
                // "./images/gallery/fit3.jpg",
                "./images/gallery/ter1.jpg",
                // "./images/gallery/ter2.jpg",
                // "./images/gallery/ter3.jpg",
                "./images/gallery/ter4.jpg",
                "./images/gallery/sh1.jpg",
                "./images/gallery/sh2.jpg",
                "./images/gallery/sh3.jpg",
                "./images/gallery/ext5.jpg",
                "./images/gallery/ext6.jpg",
                "./images/gallery/ext4.jpg",
              ]}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default Building;
