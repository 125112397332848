import React, { Component } from "react";
import { G } from "../globals";
import OverlaySpec from "./overlaySpec";
import OverlayGallery from "./overlayGallery";
import OverlayPlan from "./overlayPlan";
import OverlayDeveloper from "./overlayDeveloper";
import OverlayVirtual from "./overlayVirtual";
import OverlayVideo from "./overlayVideo";
import OverlayOccupier from "./overlayOccupiers";

class Overlays extends Component {
  constructor() {
    super();

    this.showOverlay = this.showOverlay.bind(this);
    G.showOverlay = this.showOverlay;

    this.state = {
      showSpec: null,
      showGallery: null,
      showPlan: null,
      showDev: null,
      showVirtual: null,
      showVideo: null,
      showOccupiers: null,
    };
  }

  showOverlay(overlay, data = true) {
    if (!data) this.dispatchEvent("resumeRender");
    else this.dispatchEvent("pauseRender");
    this.setState({ [overlay]: data });
  }

  dispatchEvent(eventName) {
    const eventDelegate = new CustomEvent(eventName, { bubbles: true });
    document.dispatchEvent(eventDelegate);
  }

  render() {
    return (
      <div className="overlays">
        {this.state.showSpec ? <OverlaySpec /> : null}
        {this.state.showGallery ? (
          <OverlayGallery content={this.state.showGallery} isContain={true} />
        ) : null}
        {this.state.showPlan ? (
          <OverlayPlan content={this.state.showPlan} />
        ) : null}
        {this.state.showDev ? <OverlayDeveloper /> : null}
        {this.state.showVirtual ? (
          <OverlayVirtual content={this.state.showVirtual} />
        ) : null}
        {this.state.showVideo ? (
          <OverlayVideo content={this.state.showVideo} />
        ) : null}
        {this.state.showOccupiers ? (
          <OverlayOccupier content={this.state.showOccupiers} />
        ) : null}
      </div>
    );
  }
}

export default Overlays;
