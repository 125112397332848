import U from '../utils';

export const streetMarkerData = [
	{
		text: 'Sussex Gardens',
		location: {x: -249.557, y: 0, z: 15.91},
		height: 1,
		rotation: 42.5
	},
	{
		text: 'Craven Road',
		location: {x: 144.85, y: 0, z: -42.275},
		height: 1,
		// rot in degrees
		rotation: 41
	},
	{
		text: 'Chilworth Street',
		location: {x: 195.506, y: 0, z: 128.208},
		height: 1,
		rotation: 41
	},
	{
		text: 'Praed Street',
		location: {x: -26.523, y: 0, z: 70.778},
		height: 1,
		rotation: 41
	},
	{
		text: 'Chilworth Mews',
		location: {x: 112.628, y: 0, z: 58.633},
		height: 1,
		rotation: -48
	},
	{
		text: "Bishop's Bridge Road",
		location: {x: 385.352, y: 0, z: 349.706},
		height: 1,
		rotation: 23
	},
	{
		text: 'Westway',
		location: {x: 395.538, y: 0, z: 603.007},
		height: 1,
		rotation: 15
	},
	{
		text: 'Edgware Road',
		location: {x: -582.534, y: 0, z: 340.465},
		height: 1,
		rotation: -48
	},
	{
		text: 'Westbourne Terrace',
		location: {x: 275.761, y: 0, z: 185.931},
		height: 1,
		rotation: -48
	},
	{
		text: 'Eastbourne Terrace',
		location: {x: 120.568, y: 0, z: 151.517},
		height: 1,
		rotation: -48
	}
];

export const justSpringStreet = [
	{
		text: 'Spring Street',
		location: {x: -10.172, y: 0, z: -25.036},
		height: 1,
		rotation: -48
	}
];
