import * as THREE from "three";
import { TextGeometry } from "three/examples/jsm/geometries/TextGeometry";
import { CONFIG } from "../../config";
import Entity from "../Entity";
import U from "../../utils";

class LargeTextMarker extends Entity {
	constructor(params) {
		super();

		/* 
        
        This is a large text marker that will place text in 3D space.
        Props:
        - First line
        - First line color
        - First line size
        - Second line
        - Second line color
        - Second line size
        - Second line image (for body copy)
        - Second line image size
        - Position (top left)
        - Rotation (top left?)
        - Height
        */

		this.marker = new THREE.Group();
		this.markerContent = new THREE.Group();
		this.params = params;

		const firstLineText = new TextGeometry(params.markerData.firstLineText, {
			font: params.font,
			size: params.markerData.firstLineSize,
			height: 0.01,
			curveSegments: CONFIG.r_textCurveSegments,
		});

		const firstLineTextMesh = new THREE.Mesh(
			firstLineText,
			new THREE.MeshBasicMaterial({
				color: new THREE.Color(params.markerData.firstLineColor),
				toneMapped: false
			})
		);

		if (!params.markerData.image) {
			const secondLineText = new TextGeometry(params.markerData.secondLineText, {
				font: params.font,
				size: params.markerData.secondLineSize
					? params.markerData.secondLineSize
					: params.markerData.firstLineSize,
				height: 0.01,
				curveSegments: CONFIG.r_textCurveSegments,
			});

			const secondLineTextMesh = new THREE.Mesh(
				secondLineText,
				new THREE.MeshBasicMaterial({
					color: new THREE.Color(
						params.markerData.secondLineColor
							? params.markerData.secondLineColor
							: params.markerData.firstLineColor
					),
					toneMapped: false
				})
			);

			this.markerContent.add(secondLineTextMesh);
			secondLineTextMesh.position.set(
				0,
				//second line gets placed lower with a 30% gap and then we check the difference between first line and secondline and offset accordingly
				-params.markerData.firstLineSize -
					params.markerData.firstLineSize * 0.3 +
					params.markerData.firstLineSize -
					params.markerData.secondLineSize,
				0
			);
		} else {
			const secondLineImage = new THREE.Mesh(
				new THREE.PlaneGeometry(
					params.markerData.imageSize.width,
					params.markerData.imageSize.height
				),
				new THREE.MeshBasicMaterial({
					color: new THREE.Color("#FFFFFF"),
					map: params.image,
					transparent: true,
					toneMapped: false
				})
			);

			secondLineImage.material.map.encoding = THREE.sRGBEncoding;
			secondLineImage.material.map.anisotropy = CONFIG.r_anisotropy;
			secondLineImage.material.map.magFilter = THREE.LinearFilter;
			secondLineImage.material.map.minFilter = THREE.LinearMipMapLinearFilter;
			secondLineImage.material.blending = THREE.CustomBlending
		secondLineImage.material.blendSrc = THREE.OneFactor
		secondLineImage.material.blendDst = THREE.OneMinusSrcAlphaFactor

			this.markerContent.add(secondLineImage);
			secondLineImage.position.set(
				params.markerData.imageSize.width / 2,
				-params.markerData.firstLineSize -
					params.markerData.firstLineSize * 0.3 +
					params.markerData.firstLineSize -
					params.markerData.imageSize.height,
				0
			);
		}
		this.markerContent.add(firstLineTextMesh);
		this.markerContent.rotation.set(
			-Math.PI / 2,
			0,
			U.convertDegreesToRadians(params.markerData.rotation)
		);
		this.marker.add(this.markerContent);

		setTimeout(() => {
			this.markerContent.scale.set(3, 3);
			const correctedPos = new THREE.Vector3(params.markerData.location.x, params.markerData.height ? params.markerData.height : 5, params.markerData.location.z);
			// correctedPos.applyAxisAngle(new THREE.Vector3(0, 1, 0), 3);
			this.marker.rotation.set(0, 3, 0);
			this.marker.position.set(
				correctedPos.x,
				params.markerData.height ? params.markerData.height : 5,
				correctedPos.z
			);

			
		}, 300);
	}
}

export default LargeTextMarker;
