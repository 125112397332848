import U from "../utils";
import { G } from "../globals";
import Overview from "../components/overview";
import FeatureSmart from "../components/featureSmart";
import FeatureHealthy from "../components/featureHealthy";
import featureConnected from "../components/featureConnected";
import featureResponsible from "../components/featureResponsible";
import featureQuality from "../components/featureQuality";
import Location from "../components/location";
import Building from "../components/building";
import Contact from "../components/contact";
import Floors from "../components/floors";
import Elizabeth from "../components/elizabeth";
import LocationTimes from "../components/location_times";
import Intro from "../components/intro";

export const STATE = {
  INIT: {
    ui: null,
    camData: {
      camera: {
        pos: { x: 42.63, y: 36.617, z: 87.548 },
        duration: 200,
        fov: 40,
      },
      target: {
        pos: { x: 111.921, y: 0, z: 93.424 },
        duration: 200,
      },
      controls: [],
    },
  },
  INTRO: {
    ui: Intro,
    camData: {
      camera: {
        pos: { x: 42.63, y: 36.617, z: 87.548 },
        duration: 200,
        fov: 40,
      },
      target: {
        pos: { x: 111.921, y: 0, z: 93.424 },
        duration: 200,
      },
      controls: ["gyro"],
    },
  },
  DEBUG: {
    ui: null,
    camData: {
      interactable: true,
      panSpeed: 4,
      camera: {
        pos: { x: -176.941, y: 138.954, z: 21.483 },
        duration: 3000,
        fov: 30,
      },
      target: {
        pos: { x: 86.003, y: 0, z: 43.78 },
        duration: 3000,
      },
      controls: ["all"],
    },
    markers: [
      // "featureMarkers",
      // "streetMarkers",
      // "justSpringStreetMarker",
      // "foodAmenityMarkers",
      // "wellAmenityMarkers",
      // "hotelAmenityMarkers",
      // "occupierAmenityMarkers",
      // "walkRadius",
      // "busMarkers",
      // "bikeMarkers",
      // "basicTransportMarkers",
      // "elizabethStationMarker",
      // "connectionsMarkers",
      // "poiMarkers",
    ],
  },
  OVERVIEW: {
    ui: Overview,
    options: {
      showCompass: true,
      showInstructions: true,
    },
    camData: {
      interactable: true,
      panSpeed: 4,
      camera: {
        pos: { x: -176.941, y: 138.954, z: 21.483 },
        duration: 3000,
        fov: 45,
        filmOffset: 6,
        portraitMode: {
          filmOffset: 0,
          fov: 50,
          // hOffset: {
          // 	tablet: 200,
          // 	mobile: 125,
          // },
        },
      },
      target: {
        pos: { x: 67.521, y: 10, z: 45.313 },
        duration: 3000,
      },
      controls: ["orbit", "gyro"],
    },
    markers: ["featureMarkers", "elizabethStationMarker"],
  },
  FEATURE_SMART: {
    ui: FeatureSmart,
    options: {
      showCompass: true,
      showInstructions: true,
    },
    camData: {
      // interactable: true,
      panSpeed: 4,
      camera: {
        pos: { x: 39.528, y: 42.064, z: 34.144 },
        duration: 2000,
        fov: 30,
        filmOffset: 15,
      },
      target: {
        pos: { x: 62.319, y: 0, z: 46.974 },
        duration: 2000,
      },
      // controls: ["gyro"],
    },
    markers: [],
  },
  FEATURE_HEALTHY: {
    ui: FeatureHealthy,
    options: {
      showCompass: true,
      showInstructions: true,
    },
    camData: {
      // interactable: true,
      panSpeed: 4,
      camera: {
        pos: { x: 96.936, y: 68.965, z: -45.616 },
        duration: 2000,
        fov: 30,
        filmOffset: 15,
      },
      target: {
        pos: { x: 54.072, y: 0, z: 23.932 },
        duration: 2000,
      },
      // controls: ["gyro"],
    },
    markers: [],
  },
  FEATURE_CONNECTED: {
    ui: featureConnected,
    options: {
      showCompass: true,
      showInstructions: true,
    },
    camData: {
      // interactable: true,
      panSpeed: 4,
      camera: {
        pos: { x: -168.168, y: 373.683, z: 36.094 },
        duration: 2000,
        fov: 30,
        filmOffset: 15,
      },
      target: {
        pos: { x: 37.72, y: 0, z: 54.971 },
        duration: 2000,
      },
      // controls: ["gyro"],
    },
    markers: [],
  },
  FEATURE_RESPONSIBLE: {
    ui: featureResponsible,
    options: {
      showCompass: true,
      showInstructions: true,
    },
    camData: {
      // interactable: true,
      panSpeed: 4,
      camera: {
        pos: { x: 30.731, y: 20.096, z: 38.961 },
        duration: 2000,
        fov: 30,
        filmOffset: 15,
      },
      target: {
        pos: { x: 53.636, y: 19.9, z: 34.413 },
        duration: 2000,
      },
      // controls: ["gyro"],
    },
    markers: [],
  },
  FEATURE_QUALITY: {
    ui: featureQuality,
    options: {
      showCompass: true,
      showInstructions: true,
    },
    camData: {
      // interactable: true,
      panSpeed: 4,
      camera: {
        pos: { x: -82.304, y: 129.849, z: -2.783 },
        duration: 2000,
        fov: 30,
        filmOffset: 15,
      },
      target: {
        pos: { x: 70.666, y: 0, z: 53.036 },
        duration: 2000,
      },
      // controls: ["gyro"],
    },
    markers: [],
  },
  LOCATION: {
    ui: Location,
    options: {
      showCompass: true,
      showInstructions: true,
    },
    camData: {
      interactable: true,
      panSpeed: 4,
      camera: {
        pos: { x: 579.54, y: 637.629, z: 34.703 },
        duration: 3000,
        fov: 30,
        filmOffset: 6,
        portraitMode: {
          filmOffset: 0,
          fov: 50,
          hOffset: {
            tablet: 200,
            mobile: 150,
          },
        },
      },
      target: {
        pos: { x: 122.078, y: 0, z: -59.054 },
        duration: 3000,
      },
      controls: ["pan", "gyro"],
    },
    markers: [
      "foodAmenityMarkers",
      "wellAmenityMarkers",
      "hotelAmenityMarkers",
      "walkRadius",
      "busMarkers",
      "bikeMarkers",
      "basicTransportMarkers",
      "elizabethStationMarker",
      "connectionsMarkers",
      "poiMarkers",
    ],
  },
  ELIZABETH: {
    ui: Elizabeth,
    options: {
      showCompass: true,
      showInstructions: true,
    },
    camData: {
      interactable: true,
      panSpeed: 4,
      camera: {
        pos: { x: 195.468, y: 771.918, z: 11.117 },
        duration: 3000,
        fov: 35,
        portraitMode: {
          filmOffset: 3,
          fov: 45,
          hOffset: {
            tablet: 150,
            mobile: 80,
          },
        },
      },
      target: {
        pos: { x: 32.706, y: 0, z: 58.899 },
        duration: 3000,
      },
      controls: ["pan", "gyro"],
    },
    markers: ["textElizabethMarkers", "elizabethStationMarker"],
  },
  UNDERGROUND: {
    ui: LocationTimes,
    options: {
      showCompass: true,
      showInstructions: true,
    },
    camData: {
      interactable: true,
      panSpeed: 4,
      camera: {
        pos: { x: 323.628, y: 666.93, z: -44.677 },
        duration: 3000,
        fov: 35,
        filmOffset: 3,
        portraitMode: {
          filmOffset: 3,
          fov: 40,
          hOffset: {
            tablet: 200,
            mobile: 80,
          },
        },
      },
      target: {
        pos: { x: 72.761, y: 0, z: -12.484 },
        duration: 3000,
      },
      controls: ["pan", "gyro"],
    },
    markers: [
      "undergroundTravelTimes",
      "connectionsMarkers",
      "basicTransportMarkers",
      "elizabethStationMarker",
    ],
  },
  RAIL: {
    ui: LocationTimes,
    options: {
      showCompass: true,
      showInstructions: true,
    },
    camData: {
      interactable: true,
      panSpeed: 4,
      camera: {
        pos: { x: -61.537, y: 507.147, z: 26.821 },
        duration: 3000,
        fov: 40,
        portraitMode: {
          filmOffset: 0,
          fov: 40,
          hOffset: {
            tablet: 100,
            mobile: 80,
          },
        },
      },
      target: {
        pos: { x: 59.499, y: 0, z: 207.844 },
        duration: 3000,
      },
      controls: ["pan", "gyro"],
    },
    markers: [
      "trainsTravelTimes",
      "connectionsMarkers",
      "basicTransportMarkers",
    ],
  },
  BUILDING: {
    ui: Building,
    options: {
      showCompass: true,
      showInstructions: true,
    },
    camData: {
      interactable: true,
      panSpeed: 4,
      camera: {
        pos: { x: -1.318, y: 13.128, z: 15.139 },
        duration: 3000,
        fov: 40,
        filmOffset: 6,
        portraitMode: {
          filmOffset: 0,
          fov: 30,
          hOffset: {
            tablet: 200,
            mobile: 150,
          },
        },
      },
      target: {
        pos: { x: 108.405, y: 8.8, z: 57.828 },
        duration: 3000,
      },
      controls: ["gyro"],
    },
    markers: [],
  },
  CONTACT: {
    ui: Contact,
    options: {
      showCompass: false,
      showInstructions: false,
    },
  },

  SIXTH: {
    ui: Floors,
    options: {
      showCompass: true,
      showInstructions: true,
    },
    camData: {
      interactable: true,
      panSpeed: 4,
      camera: {
        pos: { x: 71.651, y: 63, z: 93.654 },
        duration: 3000,
        fov: 30,
        filmOffset: 6,
        portraitMode: {
          filmOffset: 0,
          fov: 30,
          hOffset: {
            tablet: 200,
            mobile: 80,
          },
        },
      },
      target: {
        pos: { x: 69.961, y: 18.4, z: 52.796 },
        duration: 3000,
      },
      controls: ["pan", "rotate", "gyro"],
    },
    markers: ["sixth-floor"],
  },
  FIFTH: {
    ui: Floors,
    options: {
      showCompass: true,
      showInstructions: true,
    },
    camData: {
      interactable: true,
      panSpeed: 4,
      camera: {
        pos: { x: 71.651, y: 62.4, z: 93.654 },
        duration: 3000,
        fov: 30,
        filmOffset: 6,
        portraitMode: {
          filmOffset: 0,
          fov: 30,
          hOffset: {
            tablet: 200,
            mobile: 80,
          },
        },
      },
      target: {
        pos: { x: 69.961, y: 17, z: 52.796 },
        duration: 3000,
      },
      controls: ["pan", "rotate", "gyro"],
    },
    markers: ["fifth-floor"],
  },
  FOURTH: {
    ui: Floors,
    options: {
      showCompass: true,
      showInstructions: true,
    },
    camData: {
      interactable: true,
      panSpeed: 4,
      camera: {
        pos: { x: 71.651, y: 60, z: 93.654 },
        duration: 3000,
        fov: 30,
        filmOffset: 6,
        portraitMode: {
          filmOffset: 0,
          fov: 30,
          hOffset: {
            tablet: 200,
            mobile: 80,
          },
        },
      },
      target: {
        pos: { x: 69.961, y: 14.4, z: 52.796 },
        duration: 3000,
      },
      controls: ["pan", "rotate", "gyro"],
    },
    markers: ["fourth-floor"],
  },
  THIRD: {
    ui: Floors,
    options: {
      showCompass: true,
      showInstructions: true,
    },
    camData: {
      interactable: true,
      panSpeed: 4,
      camera: {
        pos: { x: 71.651, y: 59, z: 93.654 },
        duration: 3000,
        fov: 30,
        filmOffset: 6,
        portraitMode: {
          filmOffset: 0,
          fov: 30,
          hOffset: {
            tablet: 200,
            mobile: 80,
          },
        },
      },
      target: {
        pos: { x: 69.961, y: 10, z: 52.796 },
        duration: 3000,
      },
      controls: ["pan", "rotate", "gyro"],
    },
    markers: ["third-floor"],
  },
  SECOND: {
    ui: Floors,
    options: {
      showCompass: true,
      showInstructions: true,
    },
    camData: {
      interactable: true,
      panSpeed: 4,
      camera: {
        pos: { x: 71.651, y: 56.4, z: 93.654 },
        duration: 3000,
        fov: 30,
        filmOffset: 6,
        portraitMode: {
          filmOffset: 0,
          fov: 30,
          hOffset: {
            tablet: 200,
            mobile: 80,
          },
        },
      },
      target: {
        pos: { x: 69.961, y: 7.4, z: 52.796 },
        duration: 3000,
      },
      controls: ["pan", "rotate", "gyro"],
    },
    markers: ["second-floor"],
  },
  FIRST: {
    ui: Floors,
    options: {
      showCompass: true,
      showInstructions: true,
    },
    camData: {
      interactable: true,
      panSpeed: 4,
      camera: {
        pos: { x: 71.651, y: 53, z: 93.654 },
        duration: 3000,
        fov: 30,
        filmOffset: 6,
        portraitMode: {
          filmOffset: 0,
          fov: 30,
          hOffset: {
            tablet: 200,
            mobile: 80,
          },
        },
      },
      target: {
        pos: { x: 69.961, y: 4, z: 52.796 },
        duration: 3000,
      },
      controls: ["pan", "rotate", "gyro"],
    },
    // markers: ["featureMarkers"],
  },
  GROUND: {
    ui: Floors,
    options: {
      showCompass: true,
      showInstructions: true,
    },
    camData: {
      interactable: true,
      panSpeed: 4,
      camera: {
        pos: { x: 71.651, y: 51, z: 93.654 },
        duration: 3000,
        fov: 30,
        filmOffset: 6,
        portraitMode: {
          filmOffset: 0,
          fov: 30,
          hOffset: {
            tablet: 200,
            mobile: 80,
          },
        },
      },
      target: {
        pos: { x: 69.961, y: 1, z: 52.796 },
        duration: 3000,
      },
      controls: ["pan", "rotate", "gyro"],
    },
    markers: ["ground-floor"],
  },
  BASEMENT: {
    ui: Floors,
    options: {
      showCompass: true,
      showInstructions: true,
    },
    camData: {
      interactable: true,
      panSpeed: 4,
      camera: {
        pos: { x: 71.651, y: 49, z: 93.654 },
        duration: 3000,
        fov: 30,
        filmOffset: 6,
        portraitMode: {
          filmOffset: 0,
          fov: 30,
          hOffset: {
            tablet: 200,
            mobile: 80,
          },
        },
      },
      target: {
        pos: { x: 69.961, y: -1.4, z: 52.796 },
        duration: 3000,
      },
      controls: ["pan", "rotate", "gyro"],
    },
    markers: ["basement-floor"],
  },
};

export function GetStateArray() {
  // console.log(STATE);
  return Object.values(STATE);
}

//assign names and indices to state
const stateKeys = Object.keys(STATE);
stateKeys.map((k, i) => {
  STATE[k].name = k;

  return null;
});
