import React, { Component } from "react";
import smartImg from "../images/featureSmart_hero.png";
import smartIllust from "../images/featureSmart_illust.gif";

class FeatureSmart extends Component {
	constructor() {
		super();

		this.introAnim = this.introAnim.bind(this);
		this.outroAnim = this.outroAnim.bind(this);
	}

	componentDidMount() {
		document.addEventListener("camDone", this.introAnim);
		document.addEventListener("stateWillChange", this.outroAnim);
	}

	componentWillUnmount() {
		document.removeEventListener("camDone", this.introAnim);
		document.removeEventListener("stateWillChange", this.outroAnim);
	}

	introAnim(e) {
		if (!e.detail.state || e.detail.state.name !== "FEATURE_SMART") return;

		setTimeout(() => {
			const slideDiv = document.querySelector(".slide");
			this.fadeDivs = [...slideDiv.querySelectorAll(".fade-init")];
			this.heroImg.classList.add("swipe-reveal");
			setTimeout(() => {
				this.featureDiv.classList.add("swipe-reveal");
				this.fadeDivs.map((f) => f.classList.remove("fade"));
			}, 400);
		}, 100);
	}

	outroAnim(e) {
		if (e.detail.newState && e.detail.newState.name === "FEATURE_SMART") return;
		const swipeOuts = [...document.querySelectorAll(".swipe-init")];
		const fadeOuts = [...document.querySelectorAll(".fade-init")];
		swipeOuts.map((s) => {
			s.classList.add("swipe-out");
		});
		fadeOuts.map((f) => {
			f.classList.add("fade");
		});
	}

	render() {
		return (
			<div className="slide feature-slide clickable">
				<div className="slide-close-container">
					<div
						className="slide-close"
						onClick={() => {
							this.props.GoToState("OVERVIEW");
						}}
					></div>
				</div>
				<div className="feature-slide-wrapper">
					<div
						ref={(ref) => (this.featureDiv = ref)}
						className="feature-slide-container swipe-init"
					>
						<div className="enlarge-button feature-button"></div>
						<div
							className="feature-img-block"
							style={{ backgroundImage: `url(${smartIllust})` }}
						></div>
						<ul className="feature-list w-list-unstyled">
							<li className="feature-item-title">
								<div className="feature-title">WiredScore &#x27;Platinum&#x27;</div>
								<div className="feature-copy">
									The best global standard for digital connectivity. <br />
									Ensuring resilience, adaptability and multiple high speed
									digital connections
								</div>
							</li>
							<li className="feature-item-title">
								<div className="feature-title">SMART Enabled</div>
								<div className="feature-copy">
									Providing a 360 degree software platform that allows you to
									connect with every operational part of the building, improving
									your user experience and operational performance
								</div>
							</li>
							<li className="feature-item-title">
								<div className="feature-title">Community App</div>
								<div className="feature-copy">
									Connecting you to people in your neighbourhood, local business
									and providing access to concierge services
								</div>
							</li>
							<li className="feature-item-title">
								<div className="feature-title">Climate and lighting control</div>
								<div className="feature-copy">
									Opportunity for interface with building BMS system , giving you
									full control of your environment
								</div>
							</li>
							<li className="feature-item-title">
								<div className="feature-title">Access Management</div>
								<div className="feature-copy">
									Touchless QR technology providing safe, seamless touch-free
									controls and enhanced user and guest experience
								</div>
							</li>
						</ul>
						<div className="feature-vert-title">
							<div className="title-vert-rotate">
								<h2 className="h2 feature-vert">A smarter way to work</h2>
							</div>
						</div>
					</div>
				</div>
				<div
					ref={(ref) => (this.heroImg = ref)}
					className="feature-slide-main swipe-init"
					style={{
						backgroundColor: "#0A2342",
					}}
				>
					<div
						className="feature-smart-img "
						style={{ backgroundImage: `url(${smartImg})` }}
					></div>
				</div>
			</div>
		);
	}
}

export default FeatureSmart;
