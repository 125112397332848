import U from "../utils";

export const foodMarkers = [
	{
		text: "Heist Bank",
		subText: "Modern canalside venue for beer & pizza",
		location: { x: -43.176, y: 0, z: 437.926 },
		// location: { x: 35.334, y: 0, z: 49.856 },
		height: 8,
	},
	{
		text: "The Frontline Club",
		subText: "Modern Euro eats in lofty former factory",
		location: { x: -269.214, y: 0, z: 192.281 },
		height: 8,
	},
	{
		text: "Les Filles Cafe",
		subText: "Casual cafe and bar",
		location: { x: 218.338, y: 0, z: -249.319 },
		height: 8,
	},
	{
		text: "Darcie & May Green",
		subText: "Australian cafe and exhibition space",
		location: { x: 107.556, y: 0, z: 458.613 },
	},
	{
		text: "The Pilgrim Cafe & Lounge",
		subText: "Chic hotel with a cafe and lounge bar",
		location: { x: -108.298, y: 0, z: 79.732 },
		height: 8,
	},
	{
		text: "Vagabond",
		subText: "Wine bar, shop and kitchen",
		location: { x: 189.338, y: 0, z: 569.443 },
		height: 8,
	},
	{
		text: "Lockhouse",
		subText: "A modern pub next to the canals",
		location: { x: -311.165, y: 0, z: 402.719 },
		height: 8,
	},

	{
		text: "Beany Green",
		subText: "A relaxed and buzzing Australian cafe",
		location: { x: 207.302, y: 0, z: 584.684 },
		height: 8,
	},
	{
		text: "KuPP",
		subText: "Scandinavian style all-day cafe & bar",
		location: { x: -214.508, y: 0, z: 392.331 },
	},
	{
		text: "Fountain Abbey",
		subText: "Victorian pub with original tile & leaded windows",
		location: { x: -234.073, y: 0, z: 195.748 },
		height: 8,
	},
	{
		text: "It's all Greek to me",
		subText: "Snug spot for traditional dishes and wine",
		location: { x: -272.368, y: 0, z: 225.403 },
		height: 8,
	},
	{
		text: "Massis",
		subText: "Lebanese cuisine in glossy setting",
		location: { x: 215.457, y: 0, z: 571.244 },
		height: 8,
	},
	{
		text: "Smith's Bar & Grill",
		subText: "Smart canalside lounge & bar",
		location: { x: 150.473, y: 0, z: 508.067 },
		height: 8,
	},
	{
		text: "Laurents",
		subText: "French cafe and deli",
		location: { x: 251.233, y: 0, z: -131.416 },
		height: 8,
	},
	{
		text: "The Swan",
		subText: "18th century coaching inn",
		location: { x: 126.338, y: 0, z: -355.261 },
		height: 8,
	},
	{
		text: "Lite Bite Cafe & Bar",
		subText: "Small local sandwich bar",
		location: { x: -90.473, y: 0, z: 61.785 },
		height: 8,
	},
	{
		text: "Sawyers Arms",
		subText: "Traditional pub with contemporary decor",
		location: { x: -98.357, y: 0, z: 47.766 },
		height: 8,
	},
	{
		text: "Pergola Paddington",
		subText: "Beachy, open air food court",
		location: { x: 400.78, y: 0, z: 557.764 },
		height: 8,
	},
	{
		text: "The Union",
		subText: "Modern canalside bar with European menu",
		location: { x: 199.426, y: 0, z: 542.987 },
		height: 8,
	},
	{
		text: "Bathurst Deli",
		subText: "Local deli and cafe",
		location: { x: -37.026, y: 0, z: -242.268 },
		height: 8,
	},
	{
		text: "Angelus Restaurant",
		subText: "Small French braserrie with classic menu",
		location: { x: -48.329, y: 0, z: -235.855 },
		height: 8,
	},
	{
		text: "Poncho 8",
		subText: "Fresh Mexican food",
		location: { x: 242.989, y: 0, z: 547.944 },
		height: 8,
	},
	{
		text: "Abasto",
		subText: "Wine and empanadas",
		location: { x: -655.813, y: 0, z: -159.349 },
		height: 8,
	},
	{
		text: "The Monkey Puzzle",
		subText: "Large pub with terraced beer garden",
		location: { x: -450.72, y: 0, z: 107.16 },
		height: 8,
	},
	// {
	// 	text: 'The Summerhouse',
	// 	subText: 'Canalside dining room serving seafood',
	// 	location: U.getPosByLatLon(51.522104, -0.186126)
	// },
	{
		text: "Temaki Bros",
		subText: "Japanese street food",
		location: { x: 408.429, y: 0, z: 580.494 },
		height: 8,
	},
	{
		text: "BABEK Brothers",
		subText: "Kebabs and cocktails",
		location: { x: 414.872, y: 0, z: 558.709 },
		height: 8,
	},
	{
		text: "Smash Patty",
		subText: "Stripped back burger pop-up",
		location: { x: 389.484, y: 0, z: 572.105 },
		height: 8,
	},
	{
		text: "An Choi",
		subText: "Vietnamese street eats",
		location: { x: 432.228, y: 0, z: 563.929 },
		height: 8,
	},
	{
		text: "Rice Guys",
		subText: "Chinese comfort food",
		location: { x: -254.754, y: 0, z: 396.573 },
		height: 8,
	},
	{
		text: "Rolling Dough",
		subText: "Woodfired pizzas",
		location: { x: -238.937, y: 0, z: 394.328 },
		height: 8,
	},
	{
		text: "Kalimera",
		subText: "Greek street food",
		location: { x: -231.991, y: 0, z: 426.73 },
		height: 8,
	},
	{
		text: "Hola Guacamole",
		subText: "Mexican street food",
		location: { x: -207.241, y: 0, z: 425.999 },
		height: 8,
	},
	{
		text: "Curry On Naanstop",
		subText: "Mumbai street eats",
		location: { x: 299.269, y: 0, z: 521.387 },
		height: 8,
	},
	{
		text: "Kokodoo",
		subText: "Korean fried chicken",
		location: { x: 304.635, y: 0, z: 500.59 },
		height: 8,
	},
	{
		text: "Meat the Chef",
		subText: "Fried chicken and street food",
		location: { x: 339.092, y: 0, z: 525.151 },
		height: 8,
	},
	{
		text: "Pasta is Ready",
		subText: "Tradional Italian food",
		location: { x: 363.117, y: 0, z: 527.552 },
		height: 8,
	},
	{
		text: "The Victoria",
		subText: "19th-century pub serving comfort food",
		location: { x: -302.032, y: 0, z: -211.155 },
		height: 8,
	},
	{
		text: "The London Shell Co",
		subText: "Floating restaurant-bar serving seafood & fish",
		location: { x: 161.338, y: 0, z: 524.819 },
	},
	{
		text: "The Boathouse",
		subText: "Floating restaurant-bar serving seafood & fish",
		location: { x: -367.157, y: 0, z: 415.403 },
		height: 8,
	},
	{
		text: "Java U",
		subText: "Montreal inspired café",
		location: { x: -560.172, y: 0, z: 340.434 },
		height: 8,
	},
	{
		text: "Bonne Bouche",
		subText: "Artisan bakery",
		location: { x: -179.088, y: 0, z: 156.836 },
	},
	{
		text: "Mille Patisserie",
		subText: "Artisan bakery",
		location: { x: -12.976, y: 0, z: -46.154 },
		height: 8,
	},
	{
		text: "ASK Italian",
		subText: "Modern Italian restaurant chain",
		location: { x: -50.983, y: 0, z: -60.181 },
		height: 8,
	},
	{
		text: "Roba Bar & Restaurant",
		subText: "Modern British restaurant",
		location: { x: -283.062, y: 0, z: 110.981 },
		height: 8,
	},
	{
		text: "Taormina",
		subText: "Relaxed, family-run Italian",
		location: { x: 232.51, y: 0, z: -225.966 },
		height: 8,
	},

	{
		text: "M&S Paddington",
		subText: "Grocery shop",
		location: { x: 28.306, y: 0, z: 97.67 },
		height: 8,
	},

	{
		text: "EAT.",
		subText: "Coffee shop",
		location: { x: 2.569, y: 0, z: 121.726 },
		height: 8,
	},

	{
		text: "Barburrito",
		subText: "Mexican Restaurant",
		location: { x: 15.143, y: 0, z: 195.723 },
		height: 8,
	},

	{
		text: "Pret A Manger",
		subText: "Coffee shop",
		location: { x: 96.323, y: 0, z: 184.927 },
		height: 8,
	},

	{
		text: "Soboro",
		subText: "Bakery",
		location: { x: 33.837, y: 0, z: 183.086 },
		height: 8,
	},

	{
		text: "Wasabi Sushi & Bento",
		subText: "Japanese",
		location: { x: 35.417, y: 0, z: 127.714 },
		height: 8,
	},

	{
		text: "The Mad Bishop & Bear",
		subText: "Airy station bar with ornate decor and a chandelier",
		location: { x: 54.327, y: 0, z: 108.865 },
		height: 8,
	},

	{
		text: "Costa",
		subText: "Coffee Shop",
		location: { x: 62.237, y: 0, z: 268.957 },
		height: 8,
	},

	{
		text: "Momento",
		subText: "Modern cafe serving good coffee and cakes",
		location: { x: -110.6, y: 0, z: 108.87 },
		height: 8,
	},
	{
		text: "Mihbaj",
		subText: "Rustic coffee shop and cafe",
		location: { x: -158.182, y: 0, z: 134.095 },
		height: 8,
	},
	{
		text: "Kioskafe",
		subText: "Newsagent's and cafe",
		location: { x: -285.565, y: 0, z: 201.871 },
		height: 8,
	},
	{
		text: "Bagel Factory",
		subText: "Bagel shop",
		location: { x: -45.045, y: 0, z: 68.999 },
		height: 8,
	},
	{
		text: "The Cork and Bottle",
		subText: "Wine shop and bar",
		location: { x: -13.453, y: 0, z: -10.672 },
		height: 8,
	},
	{
		text: "San Marco Restaurant",
		subText: "Ristorante & Pizzeria",
		location: { x: -128.293, y: 0, z: 73.968 },
		height: 8,
	},
	{
		text: "London Street Brasserie",
		subText: "All day dining in chic hotel restaurant",
		location: { x: -258.584, y: 0, z: 62.504 },
		height: 8,
	},

	// CONVENIENCE STORES
	{
		text: "Sainsbury's",
		subText: "Supermarket",
		location: { x: 43.786, y: 0, z: 96.482 },
		height: 8,
	},
	{
		text: "WHSmith",
		subText: "Stationery Shop",
		location: { x: -11.075, y: 0, z: 156.968 },
		height: 8,
	},
	{
		text: "Nisa Local",
		subText: "Convenience store",
		location: { x: 263.611, y: 0, z: -106.417 },
		height: 8,
	},
	{
		text: "Co-op",
		subText: "Supermarket convenience store",
		location: { x: -383.918, y: 0, z: 425.579 },
		height: 8,
	},
	{
		text: "Tesco Express",
		subText: "Supermarket convenience store",
		location: { x: -383.24, y: 0, z: 332.931 },
		height: 8,
	},
	{
		text: "Sainsbury's Local",
		subText: "Supermarket convenience store",
		location: { x: 868.599, y: 0, z: -197.161 },
		height: 8,
	},
	{
		text: "Sainsbury's Local",
		subText: "Supermarket convenience store",
		location: { x: 874.914, y: 0, z: 205.006 },
		height: 8,
	},
	{
		text: "Chilworth Food Store",
		subText: "Convenience store",
		location: { x: 367.696, y: 0, z: 31.406 },
		height: 8,
	},
	{
		text: "Continental Food",
		subText: "Convenience store",
		location: { x: 99.852, y: 0, z: -0.333 },
		height: 8,
	},
];

export const hotelMarkers = [
	{
		text: "Hilton London Paddington",
		subText: "Hotel",
		location: { x: -19.333, y: 0, z: 87.101 },
		height: 8,
	},
	{
		text: "Mercure London Hyde Park",
		subText: "Hotel",
		location: { x: -105.64, y: 0, z: -3.158 },
		height: 8,
	},
	{
		text: "Royal Lancaster London",
		subText: "Hotel",
		location: { x: -19.344, y: 0, z: -299.757 },
		height: 8,
	},
	{
		text: "Park Grand Lancaster Gate",
		subText: "Hotel",
		location: { x: 364.402, y: 0, z: -142.404 },
		height: 8,
	},
	{
		text: "Park Grand Paddington Court London",
		subText: "Hotel",
		location: { x: 309.543, y: 0, z: -106.117 },
		height: 8,
	},
	{
		text: "Best Western Mornington Hotel",
		subText: "Hotel",
		location: { x: 208.446, y: 0, z: -290.504 },
		height: 8,
	},
	{
		text: "Novotel London Paddington",
		subText: "Hotel",
		location: { x: 409.703, y: 0, z: 520.738 },
		height: 8,
	},
	{
		text: "The Chilworth",
		subText: "Hotel",
		location: { x: 152.783, y: 0, z: 55.938 },
		height: 8,
	},
	{
		text: "Park Grand London Hyde Park",
		subText: "Hotel",
		location: { x: 296.596, y: 0, z: 143.479 },
		height: 8,
	},
	{
		text: "The Westbourne Hyde Park",
		subText: "Hotel",
		location: { x: 172.105, y: 0, z: -160.987 },
		height: 8,
	},
	{
		text: "The Pilgrim Hotel",
		subText: "Hotel",
		location: { x: -111.64, y: 0, z: 87.471 },
		height: 10,
	},
];

export const wellMarkers = [
	{
		text: "Active360 Paddleboarding",
		subText: "",
		location: { x: -264.679, y: 0, z: 412.096 },
		height: 8,
	},
	{
		text: "Dance Art",
		subText: "",
		location: { x: 105.791, y: 0, z: 13.557 },
		height: 8,
	},
	{
		text: "Nuffield Health Paddington",
		subText: "",
		location: { x: 209.339, y: 0, z: 510.236 },
	},
	{
		text: "F45 Training Paddington",
		subText: "",
		location: { x: -396.52, y: 0, z: 365.257 },
		height: 7,
	},
	{
		text: "Yoga at Floating Pocket Park",
		subText: "",
		location: { x: -366.528, y: 0, z: 363.027 },
	},
	// {
	// 	text: 'Little Venice Sports Centre',
	// 	subText: 'Fitness centre with a sports hall',
	// 	location: U.getPosByLatLon(51.52267, -0.177258)
	// },
	{
		text: "Peacock Pilates",
		subText: "",
		location: { x: 41.982, y: 0, z: -6.154 },
		height: 7,
	},
	{
		text: "Virgin Active",
		subText: "",
		location: { x: -239.413, y: 0, z: 432.742 },
		height: 8,
	},
	// {
	// 	text: 'Secret Yoga Sutra',
	// 	subText: 'Yoga studio',
	// 	location: U.getPosByLatLon(51.512547, -0.191198)
	// }
];

export const occupierMarkers = [
	{
		text: "A2Dominion",
		subText: "",
		location: { x: -20.48, y: 0, z: 390.856 },
		height: 7,
	},
	{
		text: "adam&eveDDB",
		subText: "",
		location: { x: 300.322, y: 0, z: 393.737 },
		height: 8,
	},
	{
		text: "Addison Lee",
		subText: "",
		location: { x: -1.053, y: 0, z: 379.404 },
		height: 7,
	},
	{
		text: "BT",
		subText: "",
		location: { x: -28.138, y: 0, z: 368.806 },
		height: 7,
	},
	{
		text: "BMG Chrysalis",
		subText: "",
		location: { x: -232.504, y: 0, z: 409.42 },
		height: 6,
	},
	{
		text: "McDermott",
		subText: "",
		location: { x: 119.264, y: 0, z: 122.074 },
		height: 7,
	},
	{
		text: "Kingfisher",
		subText: "",
		location: { x: 256.474, y: 0, z: 570.464 },
	},
	{
		text: "Marks & Spencer Head Office",
		subText: "",
		location: { x: -130.402, y: 0, z: 394.913 },
		height: 7,
	},
	{
		text: "Microsoft",
		subText: "",
		location: { x: 348.331, y: 0, z: 570.547 },
		height: 7,
	},
	{
		text: "Nokia",
		subText: "",
		location: { x: 393.987, y: 0, z: 575.644 },
		height: 7,
	},
	{
		text: "Mars",
		subText: "",
		location: { x: 401.989, y: 0, z: 558.46 },
		height: 7,
	},
	{
		text: "Finastra",
		subText: "",
		location: { x: 317.189, y: 0, z: 586.179 },
		height: 7,
	},
	{
		text: "Nissan Design Europe",
		subText: "",
		location: { x: 259.852, y: 0, z: 649.179 },
		height: 7,
	},
	{
		text: "The Office Group - 20 Eastbourne Tce",
		subText: "",
		location: { x: 192.34, y: 0, z: 238.74 },
		height: 15,
	},
	{
		text: "The Office Group - 19 Eastbourne Tce",
		subText: "",
		location: { x: 213.688, y: 0, z: 308.573 },
		height: 8,
	},
	{
		text: "Virgin Management",
		subText: "",
		location: { x: 322.38, y: 0, z: 631.117 },
		height: 8,
	},
	{
		text: "Cerner Corporation",
		subText: "",
		location: { x: -19.904, y: 0, z: 368.951 },
		height: 7,
	},
	{
		text: "Visa Europe",
		subText: "",
		location: { x: 272.293, y: 0, z: 503.598 },
		height: 7,
	},
	{
		text: "WeWork",
		subText: "",
		location: { x: 258.225, y: 0, z: 332.267 },
		height: 7,
	},
	{
		text: "Hellman & Friedman",
		subText: "",
		location: { x: 81.677, y: 0, z: 479.873 },
		height: 17,
	},
	{
		text: "Sony Pictures Entertainment",
		subText: "",
		location: { x: 50.503, y: 0, z: 435.897 },
		height: 17,
	},
	{
		text: "Paymentsense",
		subText: "",
		location: { x: 57.298, y: 0, z: 475.926 },
		height: 17,
	},
	// {
	// 	text: 'Alpha FX Group',
	// 	subText: '',
	// 	location: U.getPosByLatLon(51.518979, -0.177876),
	// 	height: 17
	// },
	{
		text: "Splunk",
		subText: "",
		location: { x: 68.597, y: 0, z: 461.488 },
		height: 17,
	},
	{
		text: "Premier League",
		subText: "",
		location: { x: 48.155, y: 0, z: 450.398 },
		height: 17,
	},
	{
		text: "JC Decaux",
		subText: "",
		location: { x: -232.504, y: 0, z: 409.42 },
	},
	{
		text: "Smart Pension",
		subText: "",
		location: { x: 134.672, y: 0, z: 146.654 },
		height: 7,
	},
	{
		text: "Kaspersky Lab",
		subText: "",
		location: { x: 294.198, y: 0, z: 585.548 },
		height: 8,
	},
	// {
	// 	text: 'The Press Association',
	// 	subText: '',
	// 	location: U.getPosByLatLon(51.52244, -0.162206)
	// },
	{
		text: "Tapestry/Coach",
		subText: "",
		location: { x: 31.754, y: 0, z: 488.435 },
		height: 8,
	},
	{
		text: "Alpha FX",
		subText: "",
		location: { x: 73.786, y: 0, z: 476.459 },
		height: 17,
	},
	{
		text: "Vodafone",
		subText: "",
		location: { x: 323.672, y: 0, z: 512.514 },
		height: 9,
	},
	// {
	// 	text: 'Premier League',
	// 	subText: '',
	// 	location: U.getPosByLatLon(51.519171, -0.177357)
	// },
	{
		text: "Equinor",
		subText: "",
		location: { x: 280.106, y: 0, z: 517.414 },
		height: 8,
	},
	{
		text: "Takeda",
		subText: "",
		location: { x: 361.722, y: 0, z: 524.035 },
		height: 9,
	},
	// {
	// 	text: 'EE',
	// 	subText: '',
	// 	location: U.getPosByLatLon(51.52244, -0.162206)
	// },
	{
		text: "Airline Seat Company",
		subText: "",
		location: { x: 178.655, y: 0, z: 300.867 },
		height: 8,
	},
	{
		text: "SIG Trading",
		subText: "",
		location: { x: 226.436, y: 0, z: 289.56 },
		height: 7,
	},
	{
		text: "Ascentual Events",
		subText: "",
		location: { x: 293.032, y: 0, z: 572.496 },
		height: 8,
	},
	{
		text: "WIPRO",
		subText: "",
		location: { x: 242.631, y: 0, z: 584.299 },
		height: 8,
	},
	{
		text: "Prudential Assurance",
		subText: "",
		location: { x: 267.2, y: 0, z: 554.531 },
		height: 8,
	},
	{
		text: "Sasol",
		subText: "",
		location: { x: 368.183, y: 0, z: 572.82 },
		height: 7,
	},
	{
		text: "Vertex",
		subText: "",
		location: { x: 284.111, y: 0, z: 582.83 },
		height: 8,
	},
	{
		text: "First Group",
		subText: "",
		location: { x: -41.2, y: 0, z: 391.529 },
		height: 7,
	},
	{
		text: "Statoil",
		subText: "",
		location: { x: 373.387, y: 0, z: 505.21 },
		height: 8,
	},
	// {
	// 	text: 'Mitsubishi Hitachi',
	// 	subText: '',
	// 	location: U.getPosByLatLon(51.52244, -0.162206)
	// },
	{
		text: "Cognizant Worldwide",
		subText: "",
		location: { x: 306.497, y: 0, z: 546.253 },
		height: 8,
	},
];
