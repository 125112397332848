import React, { Component } from 'react';
import GalleryThumb from './galleryThumb';

class Elizabeth extends Component {
  constructor() {
		super();

		this.introAnim = this.introAnim.bind(this);
		this.outroAnim = this.outroAnim.bind(this);
	}

	componentDidMount() {
		document.addEventListener("camDone", this.introAnim);
		document.addEventListener("stateWillChange", this.outroAnim);
	}

	componentWillUnmount() {
		document.removeEventListener("camDone", this.introAnim);
		document.removeEventListener("stateWillChange", this.outroAnim);
	}

	introAnim(e) {
		if (!e.detail.state || e.detail.state.name !== "ELIZABETH") return;

		setTimeout(() => {
			const slideDiv = document.querySelector(".slide");
			this.fadeDivs = [...slideDiv.querySelectorAll(".fade-init")];
			this.slidePopout = document.querySelector('.thumb-gallery-img');
			console.log(this.slidePopout);
			this.slidePopout.classList.add("swipe-reveal");
			setTimeout(() => {
				this.slideBg.classList.add("swipe-reveal");
				this.fadeDivs.map((f) => f.classList.remove("fade"));
			}, 200);
		}, 100);
	}

	outroAnim(e) {
		if (e.detail.newState && e.detail.newState.name === "ELIZABETH") return;
		const swipeOuts = [...document.querySelectorAll(".swipe-init")];
		const fadeOuts = [...document.querySelectorAll(".fade-init")];
		swipeOuts.map((s) => {
			s.classList.add("swipe-out");
		});
		fadeOuts.map((f) => {
			f.classList.add("fade");
		});
	}

  render() {
    return <div className="slide slide-bottom-aligned">
        <div className="slide-panel panel-red parallax">
        <div className="slide-popout elizabeth-popout clickable">
          <GalleryThumb content={['./images/crossrail-diagram.jpg']} />
          </div>
          <div className="panel-padding-container clickable fade-init fade">
            <ul className="slide-horiz-nav w-list-unstyled">
              <li className="slide-horiz-item" onClick={() => this.props.GoToState('ELIZABETH')}><strong>elizabeth line</strong></li>
              <li className="slide-horiz-item" onClick={() => this.props.GoToState('RAIL')}><strong>national rail</strong></li>
              <li className="slide-horiz-item" onClick={() => this.props.GoToState('UNDERGROUND')}><strong>underground</strong></li>
            </ul>
          </div>
          <div ref={(ref) => (this.slideBg = ref)} className="location-panel-bg swipe-init"></div>
        </div>
        <div className="slide-close-container clickable">
          <div className="slide-close" onClick={() => this.props.GoToState("LOCATION")}></div>
        </div>
      </div>;
  }
}

export default Elizabeth;