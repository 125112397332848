import * as THREE from "three";
import { Loader } from "three";
import { G } from "../globals";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { DRACOLoader } from "three/examples/jsm/loaders/DRACOLoader.js";
import Entity from "./Entity";
import U from "../utils";

class Ground extends Entity {
  constructor() {
    super();

    this.SetTransportLines.bind = this.SetTransportLines.bind(this);

    this.obj = null;

    this.currentModelIndex = 0;
    this.enableLoop = false;

    const loader = new GLTFLoader();
    const dracoLoader = new DRACOLoader();
    dracoLoader.setDecoderPath("models/draco/");
    loader.setDRACOLoader(dracoLoader);

    const maxAnisotropy = G.renderer.capabilities.getMaxAnisotropy();

    loader.load("models/fifty-v4.glb", (data) => {
      this.obj = data.scene;

      this.floors = {
        ROOF: this.obj.children.find((o) => o.name === "ROOF"),
        SIXTH: this.obj.children.find((o) => o.name === "SIXTH"),
        FIFTH: this.obj.children.find((o) => o.name === "FIFTH"),
        FOURTH: this.obj.children.find((o) => o.name === "FOURTH"),
        THIRD: this.obj.children.find((o) => o.name === "THIRD"),
        SECOND: this.obj.children.find((o) => o.name === "SECOND"),
        FIRST: this.obj.children.find((o) => o.name === "FIRST"),
        GROUND: this.obj.children.find((o) => o.name === "GROUND"),
      };

      this.groundPlane = this.obj.children.find(
        (o) => o.name === "GROUND_PLANE"
      );

      this.transpLines = {
        ELIZABETH: this.obj.children.find((o) => o.name === "ElizabethLine"),
        CENTRAL: this.obj.children.find((o) => o.name === "CentralLine"),
        CIRCLE: this.obj.children.find((o) => o.name === "CircleLine"),
        DISTRICT: this.obj.children.find((o) => o.name === "DistrictLine"),
        BAKERLOO: this.obj.children.find((o) => o.name === "BakerlooLine"),
        HAMMERSMITH: this.obj.children.find(
          (o) => o.name === "HammersmithLine"
        ),
      };

      Object.values(this.transpLines).map((t) => {
        t.visible = false;

        let color = "red";

        switch (t.name) {
          case "ElizabethLine":
            color = "#9A6BED";
            break;
          case "CentralLine":
            color = "red";
            break;
          case "CircleLine":
            color = "#3C8363";
            break;
          case "DistrictLine":
            color = "#FCD133";
            break;
          case "BakerlooLine":
            color = "#5D3B29";
            break;
          case "HammersmithLine":
            color = "#DC74A7";
            break;
          default:
            color = "red";
            break;
        }

        t.material = new THREE.MeshBasicMaterial({
          color: new THREE.Color(color),
          toneMapped: false,
          fog: false,
        });
      });

      this.floorsArray = Object.keys(this.floors);

      if (window.safari) {
        const allMats = [];
        this.obj.traverse((o) => {
          if (o.material) allMats.push(o);
        });

        allMats.map((o, i) => {
          o.castShadow = false;
          if (o.material.alphaTest > 0) o.material.alphaTest = 0;
        });
      }

      this.obj.traverse((o) => {
        if (o.isMesh && o.material && o.material.map) {
          if (o.material.name === "EXT-WALL-MULLION") {
            o.material.map.anisotropy = maxAnisotropy;
            o.material.map.magFilter = THREE.NearestFilter;
            o.material.map.minFilter = THREE.NearestFilter;
          }

          if (o.material.name === "FloorLabel") {
            o.material.color = new THREE.Color("#010810");
            o.material.map.encoding = THREE.sRGBEncoding;
            o.material.map.anisotropy = maxAnisotropy;
            o.material.map.magFilter = THREE.LinearFilter;
            o.material.map.minFilter = THREE.LinearMipMapLinearFilter;
            o.material.toneMapped = true;
            o.material.transparent = true;
            o.material.needsUpdate = true;
          }
        }
      });

      this.obj.position.set(0, 0, 0);
      this.obj.scale.set(1, 1, 1);

      this.obj.visible = true;

      this.Instantiate(this.obj, "env");

      if (!G.currentState || !G.currentState.name) {
        G.currentState = { name: "overview" };
      }

      //set building state if coming back here directly
      this.SetBuildingState(G.currentState.name);
      this.SetTransportLines(G.currentState.name);
    });
  }

  SetBuildingState(_state) {
    this.ResetBuildingState();
    switch (_state) {
      case "SIXTH":
        this.floors.ROOF.visible = false;
        break;
      case "FIFTH":
        this.floors.ROOF.visible = false;
        this.floors.SIXTH.visible = false;
        break;
      case "FOURTH":
        this.floors.ROOF.visible = false;
        this.floors.SIXTH.visible = false;
        this.floors.FIFTH.visible = false;
        break;
      case "THIRD":
        this.floors.ROOF.visible = false;
        this.floors.SIXTH.visible = false;
        this.floors.FIFTH.visible = false;
        this.floors.FOURTH.visible = false;
        break;
      case "SECOND":
        this.floors.ROOF.visible = false;
        this.floors.SIXTH.visible = false;
        this.floors.FIFTH.visible = false;
        this.floors.FOURTH.visible = false;
        this.floors.THIRD.visible = false;
        break;
      case "FIRST":
        this.floors.ROOF.visible = false;
        this.floors.SIXTH.visible = false;
        this.floors.FIFTH.visible = false;
        this.floors.FOURTH.visible = false;
        this.floors.THIRD.visible = false;
        this.floors.SECOND.visible = false;
        break;
      case "GROUND":
        this.floors.ROOF.visible = false;
        this.floors.SIXTH.visible = false;
        this.floors.FIFTH.visible = false;
        this.floors.FOURTH.visible = false;
        this.floors.THIRD.visible = false;
        this.floors.SECOND.visible = false;
        this.floors.FIRST.visible = false;
        break;
      case "BASEMENT":
        this.floors.ROOF.visible = false;
        this.floors.SIXTH.visible = false;
        this.floors.FIFTH.visible = false;
        this.floors.FOURTH.visible = false;
        this.floors.THIRD.visible = false;
        this.floors.SECOND.visible = false;
        this.floors.FIRST.visible = false;
        this.floors.GROUND.visible = false;
        break;
      default:
        break;
    }
  }

  ResetBuildingState() {
    if (!this.floors) return;
    this.floorsArray.map((fl) => {
      if (!this.floors[fl]) return;
      this.floors[fl].visible = true;
      return null;
    });
  }

  SetTransportLines(newStateName) {
    if (!this.transpLines) return;
    if (newStateName === "LOCATION") {
      console.log(newStateName);
      Object.values(this.transpLines).map((t) => (t.visible = true));
    } else if (newStateName === "ELIZABETH") {
      this.transpLines.ELIZABETH.visible = true;
    } else if (newStateName === "UNDERGROUND") {
      this.transpLines.CENTRAL.visible = true;
      this.transpLines.CIRCLE.visible = true;
      this.transpLines.DISTRICT.visible = true;
      this.transpLines.BAKERLOO.visible = true;
      this.transpLines.HAMMERSMITH.visible = true;
    } else {
      Object.values(this.transpLines).map((t) => (t.visible = false));
    }
  }

  onStateChange(newState) {
    console.log(newState);
    if (this.floors) {
      this.SetBuildingState(newState.name);
      this.SetTransportLines(newState.name);
    }

    if (!this.groundPlane) return;

    if (newState.name === "DEBUG") {
      this.transpLines.ELIZABETH.visible = true;
    }

    if (newState.name === "BASEMENT") {
      this.groundPlane.visible = false;
    } else this.groundPlane.visible = true;
  }
}

export default Ground;
